<template>
  <div class="content_main" @scroll="handleScroll">
    <div class="banner animate__animated animate__bounceInLeft">
      <img src="../../assets/images/n-banner02.jpg" alt="">
    </div>
    <div class="bg animate__animated animate__fadeInUp">
      <div class="module-title">
        整合营销平台
        <span>Integrated marketing platform</span>
      </div>
      <div class="module-title">
        <div class="mt_line"></div>
        产品介绍
        <span>Product introduction</span>
      </div>
      <div class="product_introduct_text content">
        <div>
          旅游目的地整合营销平台以旅游供给侧为出发点，以目的地旅游资源整合整合为手段，以目的地营销为目的，为旅游目的地运营提供技术和服务支撑，打造旅游目的地整体形象。
        </div>
      </div>
      <!-- <div class="product_introduct">
        <h4>子系统</h4>
      </div> -->
      <div class="module-title">
        <div class="mt_line"></div>
        功能
        <span>Subsystem</span>
        
      </div>
      <div class="product_z_system content">
        <div class="tag">
          <img src="./image/zhhy.png" alt="">
          <span>整合会员</span>
        </div>
        <div class="tag">
          <img src="./image/zhfw.png" alt="">
          <span>整合服务</span>
        </div>
        <div class="tag">
          <img src="./image/zhqd.png" alt="">
          <span>整合渠道</span>
        </div>
        <div class="tag">
          <img src="./image/zhzy.png" alt="">
          <span>整合资源</span>
        </div>
      </div>
      
    </div>
    <div class="module-title">
      <div class="mt_line"></div>
      效果展示
      <span>Effect display</span>
    </div>
    <div class="content zhyx_img">
      <img src="./image/zhyx.jpg" alt="">
    </div>
    <div class="footer">
      <footerPage />
    </div>
  </div>
</template>

<script>
import footerPage from '../../components/footer/footer'
export default {
  components:{
    footerPage
  },
  data() {
    return {
      tagList:[
        {
          id:0,
          name:'整合资源'
        },
        {
          id:1,
          name:'整合服务'
        },
        {
          id:2,
          name:'整合渠道'
        },
        {
          id:3,
          name:'整合会员'
        },
      ]
    }
  },
  methods: {
    handleScroll(e){
      console.log(e)
    }
  },
}
</script>

<style scoped lang='less'>
@import '../../assets/css/content.less';
.content_main{
  height: 100%;
  width: 100%;
  
  // background-image: url('../../assets/images/image1.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.bg{
  padding-bottom: 50px;
}
.banner{
  width: 100%;
  img{
    width: 100%;
    border: 0;
    display: block;
  }
}
.title{
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  h4{
    font-size: 30px;
  }
}
.module-title {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 26px;
  color: #0080cc;
  text-align: center;
  font-weight: normal;
  span {
    display: block;
    color: #818181;
    text-transform: uppercase;
    font-size: 19px;
    margin-top: 5px;
  }
}
.mt_line {
  width: 100%;
  height: 83px;
  margin-bottom: 20px;
  background: url('./../../../static/images/ai/btline.png') no-repeat center top;
}
.product_introduct{
  text-align: center;
  height: 80px;
  line-height: 80px;
  font-size: 2rem;
  background: #f5f5f5;
  margin: 0 10%;
  h4{
    font-size: 30px;
    line-height: 80px;
  }
}
.product_introduct_text{
  margin-top: 2rem;
  div{
    font-size: 18px;
    line-height: 40px;
    text-indent:3rem;
    margin-bottom: 20px;
  }
}
.product_z_system{
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .tag{
    width: 29.333%;
    margin: 1% 2%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 1px solid #cccccc;
     padding: 0 4%;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
      height: 90%;
    }
    span{
      width: 80%;
      display: inline-block;
      text-align: left;
    }

  }
}
.image_box{
  margin: 40px 0;
  img{
    width: 100%;
    margin: 20px 0;
  }
}
.zhyx_img{
  img{
    width: 100%;
  }
}
.footer{
  width: 100%;
  margin-top: 20px;
}
</style>
