<template>
  <div class="content_main" @scroll="handleScroll">
    <div class="banner animate__animated animate__bounceInLeft">
      <img src="../../assets/images/n-banner02.jpg" alt="">
    </div>
    <div class="bg animate__animated animate__fadeInUp">
      <div class="module-title">
		  二销管理系统
        <span>Second sales management system</span>
      </div>
      <div class="module-title">
        <div class="mt_line"></div>
        产品介绍
        <span>Product introduction</span>
      </div>
      <div class="product_introduct_text content">
        <div>
			将景区二销业务流程拆解划分，从五个方向切入，全面系统化管理二销流程，提升流程管理、合理分配权限;
			总部决策，门店执行，最大程度减少门店工作量。准确细化的基础业务数据，提供各个维度的数据汇总分析以及准确的进销存报表。
        </div>
      </div>
      
      <div class="module-title">
        <div class="mt_line"></div>
        子系统
        <span>Subsystem</span>
        <div class="product_z_system content">
          <div class="tag">
            <img src="../application/images/iconsNote_15.png" alt="">
            <span>前台销售APOS</span>
          </div>
          <div class="tag">
            <img src="../application/images/iconsNote_02.png" alt="">
            <span>商品管理</span>
          </div>
          <div class="tag">
            <img src="../application/images/iconsNote_05.png" alt="">
            <span>采购管理价格管理</span>
          </div>
          <div class="tag">
            <img src="../application/images/iconsNote_19.png" alt="">
            <span>库存管理</span>
          </div>
          <div class="tag">
            <img src="../application/images/iconsNote_18.png" alt="">
            <span>分析报表</span>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <footerPage />
    </div>
  </div>
</template>

<script>
import footerPage from '../../components/footer/footer'
export default {
  components:{
    footerPage
  },
  methods: {
    handleScroll(e){
      console.log(e)
    }
  },
}
</script>

<style scoped lang='less'>
@import '../../assets/css/content.less';
.content_main{
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.bg{
  padding-bottom: 50px;
}
.banner{
  width: 100%;
  img{
    width: 100%;
    border: 0;
    display: block;
  }
}
.title{
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  h4{
    font-size: 30px;
  }
}
.module-title {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 26px;
  color: #0080cc;
  text-align: center;
  font-weight: normal;
  span {
    display: block;
    color: #818181;
    text-transform: uppercase;
    font-size: 19px;
    margin-top: 5px;
  }
}
.mt_line {
  width: 100%;
  height: 83px;
  margin-bottom: 20px;
  background: url('./../../../static/images/ai/btline.png') no-repeat center top;
}
.product_introduct{
  text-align: center;
  height: 80px;
  line-height: 80px;
  font-size: 2rem;
  background: #f5f5f5;
  margin: 0 10%;
  h4{
    font-size: 30px;
    line-height: 80px;
  }
}
.product_introduct_text{
  margin-top: 2rem;
  div{
    font-size: 18px;
    line-height: 40px;
    text-indent:3rem;
    margin-bottom: 20px;
  }
}
.product_z_system{
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .tag{
    width: 29.333%;
    margin: 1% 2%;
    height: 50px;
    font-size: 18px;
    color: #000;
    line-height: 50px;
    text-align: center;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0 4%;
    img{
      height: 90%;
    }
    span{
      display: inline-block;
      width: 80%;
      display: inline-block;
      text-align: left;
      margin-left: 10px;
    }
  }
}
.image_box{
  margin: 40px 0;
  img{
    width: 100%;
    margin: 20px 0;
  }
}
.zhyx_img{
  padding: 0 1%;
  img{
    width: 100%;
  }
}
.footer{
  width: 100%;
  margin-top: 20px;
}
</style>
