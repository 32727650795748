<template>
  <div class="content_box" :class="firstActive?'animate__animated animate__fadeInUp':''">
    <div class="title">
      <h2>数据展示</h2>
      <span>Data display</span>
    </div>
    <div class="main-box main-box-active">
      <div class="data-box a">
        <div class="lbx-out">
          <div class="lbx-in lbx-in-bg" id="lbx-third">
            <span class="text-num" id="text-num">{{numberScenic}}<span style="font-size:3rem">+</span></span>
            <span class="text-title-bottom">家服务景区</span>
          </div>
        </div>
        <div class="lbx-out">
          <div class="lbx-in lbx-in-bg" id="lbx-third">
            <span class="text-num" id="text-num3">{{numberOne}}<span style="font-size:3rem">+</span></span>
            <span class="text-title-bottom">分销商</span>
          </div>
        </div>
        <div class="lbx-out">
          <div class="lbx-in lbx-in-bg" id="lbx-third">
            <span class="text-num" id="text-num">{{numberTwo}}<span style="font-size:3rem">家+</span></span>
            <span class="text-title-bottom">对接接口数量</span>
          </div>
        </div>
      </div>
      <div class="fgx"></div>
      <div class="data-box b">
        <div class="lbx-out">
          <div class="lbx-in lbx-in-bg" id="lbx-third">
            <span class="text-num" id="text-num3">{{numberthree}}<span style="font-size:3rem">亿+</span></span>
            <span class="text-title-bottom">B2B交易额</span>
          </div>
        </div>
        <div class="lbx-out">
          <div class="lbx-in lbx-in-bg" id="lbx-third">
            <span class="text-num" id="text-num">{{numberFour}}<span style="font-size:3rem">亿+</span></span>
            <span class="text-title-bottom">年游客数量</span>
          </div>
        </div>
        <div class="lbx-out">
          <div class="lbx-in lbx-in-bg" id="lbx-third">
            <span class="text-num" id="text-num">{{numberFive}}<span style="font-size:3rem">亿+</span></span>
            <span class="text-title-bottom">游客信息数量</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    firstActive:{
      type:Boolean
    },
    numberScenic:{
      type:Number
    },
    numberOne:{
      type:Number
    },
    numberTwo:{
      type:Number
    },
    numberthree:{
      type:Number
    },
    numberFour:{
      type:Number
    },
    numberFive:{
      type:Number
    }
  },
}
</script>

<style scoped lang='less'>
@import '../../../assets/css/content.less';
.number_box{
  padding-top: 14rem;
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .scenic_number{
    width: 50%;
    text-align: right;
    font-size: 5rem;
    padding-right: 20px;
    box-sizing: border-box;
    color: orange;
  }
  .scenic_name{
    width: 50%;
    text-align: left;
    font-size: 2rem;
    .scenic_number_icon{
      color: orange;
    }
    .scenic_name_text{
      color: #ffffff;
      font-weight: 600;
    }
  }
}
.a_left{
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10%;
  box-sizing: border-box;
}
.a_right{
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10%;
  box-sizing: border-box;
}
.main-box{
  
  box-sizing: border-box;
}
.data-box{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 250px;
}
.text-num{
  display: block;
  height: 100%;
  text-align: center;
  width: auto;
  line-height: 20rem;
  font-size: 6rem;
  font-weight: 700;
  transform: rotate(-0deg);
  margin: 0 auto;
  color: orange;
}
.text-title{
  display: block;
  text-align: center;
  width: auto;
  transform: rotate(-0deg);
  margin: 0 auto;
  color: #fff;
  font-size: 30px;
  position: absolute;
  top: 50px;
  left: 0px;
  width: 100%;
}
.text-title-bottom{
  display: block;
  text-align: center;
  width: auto;
  transform: rotate(-0deg);
  margin: 0 auto;
  color: #fff;
  font-size: 26px;
  position: absolute;
  bottom: 6rem;
  left: 0px;
  width: 100%;
}
.main-box-lbx{
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  margin: 0 auto;
}
.lbx-out{
  width: 200px;
  height: 230px;
  overflow: hidden;
  transform: rotate(-60deg) skewY(30deg);
  position: relative;
  margin: 0 3%;
}
.lbx-in{
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #ffffff;
  overflow: hidden;
  transform: skewY(-30deg) rotate(60deg) translateZ(0);
  cursor: pointer;
  position: relative;
}
.lbx-img{
  display: block;
  width: auto;
  height: 100%;
  text-align: center;
  color: #fff;
  overflow: hidden;
  position: absolute;
  transform: rotate(-30deg);
  margin: 0 auto;
  left: -100%;
  right: -100%;
}
.lbx-text{
  width: auto;
  height: 100%;
  text-align: center;
  margin: 0 auto;
  left: -100%;
  right: -100%;
  color: #fff;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  transform: rotate(-0deg) translateY(70%);
  cursor: pointer;
}
.lbx-text>div{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.lbx-text>div>span{
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}
.lbx-active{
  animation: lbx-text linear 0.5s;
  background-color: rgba(53, 192, 225, 0.8);
  transform: rotate(-30deg) translateY(0);
}
.lbx-close-active{
  animation: lbx-text-close linear 0.5s;
  background-color: rgba(0, 0, 0, 0.5);
  transform: rotate(-30deg) translateY(70%);
}
@keyframes lbx-text{
  0%{
    transform: rotate(-30deg) translateY(70%);
  }
  10%{
    transform: rotate(-30deg) translateY(63%);
  }
  20%{
    transform: rotate(-30deg) translateY(56%);
  }
  30%{
    transform: rotate(-30deg) translateY(49%);
  }
  40%{
    transform: rotate(-30deg) translateY(42%);
  }
  50%{
    transform: rotate(-30deg) translateY(35%);
  }
  60%{
    transform: rotate(-30deg) translateY(28%);
  }
  70%{
    transform: rotate(-30deg) translateY(21%);
  }
  80%{
    transform: rotate(-30deg) translateY(14%);
  }
  90%{
    transform: rotate(-30deg) translateY(7%);
  }
  100%{
    transform: rotate(-30deg) translateY(0);
  }
}
@keyframes lbx-text-close{
  0%{
    transform: rotate(-30deg) translateY(0);
  }
  10%{
    transform: rotate(-30deg) translateY(7%);
  }
  20%{
    transform: rotate(-30deg) translateY(14%);
  }
  30%{
    transform: rotate(-30deg) translateY(21%);
  }
  40%{
    transform: rotate(-30deg) translateY(28%);
  }
  50%{
    transform: rotate(-30deg) translateY(35%);
  }
  60%{
    transform: rotate(-30deg) translateY(42%);
  }
  70%{
    transform: rotate(-30deg) translateY(49%);
  }
  80%{
    transform: rotate(-30deg) translateY(56%);
  }
  90%{
    transform: rotate(-30deg) translateY(63%);
  }
  100%{
    transform: rotate(-30deg) translateY(70%);
  }
}
.lbx-text-active{
  animation: lbx-text-box linear 0.5s;
  transform: translateY(50%);
}
.lbx-close-text-active{
  animation: lbx-close-text-box linear 0.5s;
  transform: translateY(3%);
}
@keyframes lbx-text-box{
  0%{
    transform: translateY(3%);
  }
  10%{
    transform: translateY(7.7%);
  }
  20%{
    transform: translateY(12.4%);
  }
  30%{
    transform: translateY(17.1%);
  }
  40%{
    transform: translateY(21.8%);
  }
  50%{
    transform: translateY(26.5%);
  }
  60%{
    transform: translateY(31.2%);
  }
  70%{
    transform: translateY(35.9%);
  }
  80%{
    transform: translateY(40.6%);
  }
  90%{
    transform: translateY(45.3%);
  }
  100%{
    transform: translateY(50%);
  }
}
@keyframes lbx-close-text-box{
  0%{
    transform: translateY(50%);
  }
  10%{
    transform: translateY(45.3%);
  }
  20%{
    transform: translateY(40.6%);
  }
  30%{
    transform: translateY(35.9%);
  }
  40%{
    transform: translateY(31.2%);
  }
  50%{
    transform: translateY(26.5%);
  }
  60%{
    transform: translateY(21.8%);
  }
  70%{
    transform: translateY(17.1%);
  }
  80%{
    transform: translateY(12.4%);
  }
  90%{
    transform: translateY(7.7%);
  }
  100%{
    transform: translateY(3%);
  }
}
.title{
  margin-top: 2%;
  
  text-align: center;
  width: 100%;
  color: #ffffff;
}
.title>h2{
  font-size: 2.5rem;
}
.title>span{
  font-size: 1.5rem;
}
.data-box{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 250px;
}
.main-box-active{
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 2%;
  box-sizing: border-box;
}
.data-box-active{
  padding: 0 17%;
  box-sizing: border-box;
  transform: translateY(-100px);
}
.data-box-active2{
  transform: translateY(-200px);
}
.lbx-in-bg{
  background-color: rgba(53,192,255, 0.5);
}
.fgx{
  width: 100%;
  height: 0;
  border-bottom: 2px solid rgba(53, 192, 255, 0.5);
}
.a{
  margin-bottom: 1.5%;
}
.b{
  margin-top: 1.5%;
}
</style>
