import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
//首页
import home from '../views/home/home.vue'
//产品
import pardwareProducts from '../views/product/pardwareProducts.vue'
import O2OpmsSystem from '../views/product/O2OpmsSystem.vue'
import bigDataCenter from '../views/product/bigDataCenter.vue'
import cytSystem from '../views/product/cytSystem.vue'
import zhyxSystem from '../views/product/zhyxSystem.vue'
import zhgkPlatform from '../views/product/zhgkPlatform.vue'

import erxPlatform from '../views/product/erxPlatform.vue'
import ittPlatform from '../views/product/ittPlatform.vue'
//应用
// import application from '../views/application/index.vue'
import smartScenicSpot from '../views/application/smartScenicSpot.vue'
import theatre from '../views/application/theatre.vue'
import themePark from '../views/application/themePark.vue'
import globalTourism from '../views/application/globalTourism.vue'
import museum from '../views/application/museum.vue'
import iceAndSnowField from '../views/application/iceAndSnowField.vue'

import beautifulVillage from '../views/application/beautifulVillage.vue'
import govManagement from '../views/application/govManagement.vue'
import prdAndEdu from '../views/application/prdAndEdu.vue'
import digitalGroup from '../views/application/digitalGroup.vue'

//案例
import casePage from '../views/case/index.vue'
//5G
import AI from './../views/AI'
//智慧文旅
import smartCulturalTour from '../views/smartCulturalTour/index.vue'
//关于
import realtimeInfo from '../views/about/realtimeInfo.vue'
import enterpriseIntroduction from '../views/about/enterpriseIntroduction.vue'
import ectripRealtimeInfo from '../views/about/ectripRealtimeInfo.vue'
import contactUs from '../views/about/contactUs.vue'
const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path:'/home',
    name:'home',
    component:home
  },
  {
    path: '/ai',
    name: 'ai',
    component: AI
  },
  {
    path:'/pardwareProducts',
    name:'pardwareProducts',
    component:pardwareProducts
  },
  {
    path:'/O2OpmsSystem',
    name:'O2OpmsSystem',
    component:O2OpmsSystem
  },
  {
    path:'/bigDataCenter',
    name:'bigDataCenter',
    component:bigDataCenter
  },
  {
    path:'/cytSystem',
    name:'cytSystem',
    component:cytSystem
  },
  {
    path:'/zhyxSystem',
    name:'zhyxSystem',
    component:zhyxSystem
  },
  {
    path:'/erxPlatform',
    name:'erxPlatform',
    component:erxPlatform
  },
  {
    path:'/ittPlatform',
    name:'ittPlatform',
    component:ittPlatform
  },
  {
    path:'/zhgkPlatform',
    name:'zhgkPlatform',
    component:zhgkPlatform
  },
  {
    path:'/smartScenicSpot',
    name:'smartScenicSpot',
    component:smartScenicSpot
  },
  {
    path:'/theatre',
    name:'theatre',
    component:theatre
  },
  {
    path:'/themePark',
    name:'themePark',
    component:themePark
  },
  {
    path:'/globalTourism',
    name:'globalTourism',
    component:globalTourism
  },
  {
    path:'/museum',
    name:'museum',
    component:museum
  },

  {
    path:'/beautifulVillage',
    name:'beautifulVillage',
    component:beautifulVillage
  },

  {
    path:'/digitalGroup',
    name:'digitalGroup',
    component:digitalGroup
  },

  {
    path:'/govManagement',
    name:'govManagement',
    component:govManagement
  },

  {
    path:'/prdAndEdu',
    name:'prdAndEdu',
    component:prdAndEdu
  },
  {
    path:'/iceAndSnowField',
    name:'iceAndSnowField',
    component:iceAndSnowField
  },
  {
    path:'/casePage',
    name:'casePage',
    component:casePage
  },
  {
    path:'/smartCulturalTour',
    name:'smartCulturalTour',
    component:smartCulturalTour
  },
  {
    path:'/realtimeInfo',
    name:'realtimeInfo',
    component:realtimeInfo
  },
  {
    path:'/enterpriseIntroduction',
    name:'enterpriseIntroduction',
    component:enterpriseIntroduction
  },
  {
    path:'/ectripRealtimeInfo',
    name:'ectripRealtimeInfo',
    component:ectripRealtimeInfo
  },
  {
    path:'/contactUs',
    name:'contactUs',
    component:contactUs
  },
  
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition){
    return { x: 0, y: 0 }
  }
})


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
