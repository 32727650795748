<template>
  <div class="home">
    <div v-show="showSwiper">
      <swiper  :options="swiperOption" ref="mySwiper"  @slideChangeTransitionStart="onSwiperSlideChangeTransitionStart">
        <swiper-slide style="margin:0;width:100%;height:auto;">
          <swiper-view />
        </swiper-slide>
        <swiper-slide style="margin:0;">
          <div class="img a">
            <data-display 
              :firstActive='firstActive' 
              :numberScenic='numberScenic' 
              :numberOne='numberOne'
              :numberTwo='numberTwo'
              :numberthree='numberthree'
              :numberFour='numberFour'
              :numberFive='numberFive' />
          </div>
        </swiper-slide>
        <swiper-slide style="margin:0;">
          <div class="img b">
            <main-business :secondActive="secondActive" />
          </div>
        </swiper-slide>
        <swiper-slide style="margin:0;">
          <div class="img c">
            <application-scenario :thirdActive="thirdActive" />
            <div class="footer">
              <footer-page />
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination"  slot="pagination"></div>
      </swiper>
    </div>

    <!-- 屏幕宽度小于1300 -->
    <div v-show="!showSwiper" class="no_swiper" @scroll="handerScroll">
      <div>
        <swiperViewAno />
      </div>
      <div>
        <div class="img a">
          <data-display 
            :firstActive='firstActive' 
            :numberScenic='numberScenic' 
            :numberOne='numberOne'
            :numberTwo='numberTwo'
            :numberthree='numberthree'
            :numberFour='numberFour'
            :numberFive='numberFive' />
        </div>
      </div>
      <div>
        <div class="img b">
          <main-business :secondActive="secondActive" />
        </div>
      </div>
      <div>
        <div class="img c">
          <application-scenario :thirdActive="thirdActive" />
          <div >
            <footer-page />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swiperView from './options/swiper'
import swiperViewAno from './options/swiper01'
import dataDisplay from './options/dataDisplay'
import mainBusiness from './options/mainBusiness'
import applicationScenario from './options/applicationScenario'
import footerPage from '../../components/footer/footer'
export default {
  components:{
    swiperView,
    swiperViewAno,
    dataDisplay,
    mainBusiness,
    applicationScenario,
    footerPage
  },
  data() {
    return {
      swiperOption: {
        direction: 'vertical',
        slidesPerView: 1,
        // setWrapperSize: true,
        mousewheel: true,
        // autoHeight: true,
        height: window.innerHeight,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      dataFlag:false,
      numberScenic:3690,
      numberOne:7480,
      numberTwo:35,
      numberthree:1000,
      numberFour:0,
      numberFive:1,
      timer:null,
      timer1:null,
      timer2:null,
      timer3:null,
      timer4:null,
      timer5:null,
      firstActive:false,
      secondActive:false,
      thirdActive:false,
      screenWidth: document.body.clientWidth,
      showSwiper:document.body.clientWidth<1300?false:true,
      isScroll:true
    }
  },
  mounted() {
    const that = this
    window.onresize = () => {
      return (
        ()=>{
          // window.screenWidth = document.body.clientWidth
          that.screenWidth = document.body.clientWidth
        }
      )()
    }
  },
  watch: {
    'screenWidth'(val){
      if(val<1300){
        this.showSwiper = false
      } else {
        this.showSwiper = true
      }
    }
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    }
  },
  methods: {
    onSwiperSlideChangeTransitionStart(){
      if(this.swiper.activeIndex == 1){
        this.dataFlag = true
        this.changeNumber()
        this.firstActive = true
      } else {
        this.firstActive = false
        this.dataFlag = false
        this.numberScenic = 3690
        this.numberOne = 7480
        this.numberTwo = 35
        this.numberthree = 1000
        this.numberFour = 0
        this.numberFive = 1
        clearInterval(this.timer)
        clearInterval(this.timer1)
        clearInterval(this.timer2)
        clearInterval(this.timer3)
        clearInterval(this.timer4)
        clearInterval(this.timer5)
      }
      if(this.swiper.activeIndex == 2){
        this.secondActive = true
      } else {
        this.secondActive = false
      }
      if(this.swiper.activeIndex == 3){
        this.thirdActive = true
      } else {
        this.thirdActive = false
      }
    },
    changeNumber(){
      let that = this
      that.timer = setInterval(()=>{
        that.numberScenic += 131
        if(that.numberScenic == 5000){
          clearInterval(that.timer)
        }
      },100)
      that.timer1 = setInterval(()=>{
        that.numberOne += 452
        if(that.numberOne == 12000){
          clearInterval(that.timer1)
        }
      },100)
      that.timer2 = setInterval(()=>{
        that.numberTwo += 1
        if(that.numberTwo == 50){
          clearInterval(that.timer2)
        }
      },100)
      that.timer3 = setInterval(()=>{
        that.numberthree += 25
        if(that.numberthree == 1500){
          clearInterval(that.timer3)
        }
      },100)
      that.timer4 = setInterval(()=>{
        that.numberFour += 5
        if(that.numberFour == 30){
          clearInterval(that.timer4)
        }
      },200)
      that.timer5 = setInterval(()=>{
        that.numberFive += 1
        if(that.numberFive == 5){
          clearInterval(that.timer5)
        }
      },200)
    },
    handerScroll(e){
      this.firstActive = false
        this.dataFlag = false
        this.numberScenic = 3690
        this.numberOne = 7480
        this.numberTwo = 35
        this.numberthree = 1000
        this.numberFour = 0
        this.numberFive = 1
        clearInterval(this.timer)
        clearInterval(this.timer1)
        clearInterval(this.timer2)
        clearInterval(this.timer3)
        clearInterval(this.timer4)
        clearInterval(this.timer5)
      let scrollTop = e.srcElement.scrollTop
      if(scrollTop>10){
        let that = this
        that.timer = setInterval(()=>{
          that.numberScenic += 131
          if(that.numberScenic == 5000){
            clearInterval(that.timer)
          }
        },100)
        that.timer1 = setInterval(()=>{
          that.numberOne += 452
          if(that.numberOne == 12000){
            clearInterval(that.timer1)
          }
        },100)
        that.timer2 = setInterval(()=>{
          that.numberTwo += 1
          if(that.numberTwo == 50){
            clearInterval(that.timer2)
          }
        },100)
        that.timer3 = setInterval(()=>{
          that.numberthree += 25
          if(that.numberthree == 1500){
            clearInterval(that.timer3)
          }
        },100)
        that.timer4 = setInterval(()=>{
          that.numberFour += 5
          if(that.numberFour == 30){
            clearInterval(that.timer4)
          }
        },200)
        that.timer5 = setInterval(()=>{
          that.numberFive += 1
          if(that.numberFive == 5){
            clearInterval(that.timer5)
          }
        },200)
      }
    }
  },
}
</script>

<style>
  ::-webkit-scrollbar {
    display: none;
  }
</style>

<style lang='less' scoped>

.content{
  height: 100%;
  width: 100%;
}
.img{
  width: 100%;
  height: 100%;
  border: 0;
  padding-top:6rem;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.a{
  background-image: url('../../assets/images/image2.jpg');
}
.b{
  background-image: url('../../assets/images/image8.jpg');
}
.c{
  background-image: url('../../assets/images/image05.jpg');
}
.footer{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.home{
  height: 100%;
  overflow-y: auto;
}
.no_swiper{
  height: 100%;
  overflow-y: auto;
}
.no_swiper>div{
  height: auto;
}
</style>
