const CONFIG = {
  //0-武夷山 1-神农架 2-婺源 3-重庆武隆 4-张掖丹霞
  // 5-锦绣中华 6-海南呀诺达 7-丹霞山 8-张家界武陵源
  // 9-阿尔山 10-克什克腾石头阵 11-亚布力 12-湖北省博物馆 13-新疆吐鲁番
  // 14-镜泊湖 15-罗浮山 16-瘦西湖 17-九寨沟
  companyUrl: [
    'http://www.wyschina.com/','http://ly.snj.gov.cn/','http://www.wuyuanleyou.com/','http://www.wlkst.com/','https://www.zhangyegeopark.com/',
    'http://www.szjxzh.com.cn/','http://www.yanoda.com/', 'http://dxs.sg.gov.cn/','http://www.hnzjj.com/',
    'http://www.aesly.com/', 'http://58.18.128.74:9061/home/home.action','http://www.yabuliski.cn/','http://www.hbww.org/','http://w.goturpan.com/',
    'http://www.jphgroup.cn/', 'http://www.jphgroup.cn/', 'http://www.shouxihu.net/', 'https://www.jiuzhai.com/'
  ]
}
export default CONFIG