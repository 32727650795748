<template>
  <div class="content_main" @scroll="handleScroll">
    <div class="banner animate__animated animate__bounceInLeft">
      <img src="../../assets/images/n-banner02.jpg" alt="">
    </div>
    <div class="bg animate__animated animate__fadeInUp">
      <div class="module-title">
        大数据分析系统
        <span>Big data analysis system</span>
      </div>
      <div class="module-title">
        <div class="mt_line"></div>
        产品介绍
        <span>Product introduction</span>
      </div>
      <div class="product_introduct_text content">
        <div>
          大数据分析系统是针对旅游目的地需求研发的一套集智慧旅游、网络营销、在线服务、大数据管理于一体的解决方案，利用云计算、物联网等新技术，通过互联网、移动互联网，借助便携的上网终端，对游客信息进行多维度的精准分析和有效预测。
        </div>
        <div>
          通过整合旅游监管数据、旅游行业数据，主动感知旅游资源、旅游经济、旅游活动等方面的信息，探索旅游价值洼地、提高旅游企业的管理、服务及营销水平；提供目的地品牌形象管理、舆情分析、营销推广、事件预警、业务决策支持、实名客史管理等服务。
        </div>
      </div>
      <!-- <div class="product_introduct">
        <h4>子系统</h4>
      </div> -->
      <div class="module-title">
        <div class="mt_line"></div>
        功能
        <span>Subsystem</span>
        
      </div>
      <div class="product_z_system content">
        <div class="tag">
          <img src="./image/yxjk.png" alt="">
          <span>营销监控系统</span>
        </div>
        <div class="tag">
          <img src="./image/yyjc.png" alt="">
          <span>运营决策系统</span>
        </div>
        <div class="tag">
          <img src="./image/smks.png" alt="">
          <span>实名客史管理系统</span>
        </div>
      </div>
    </div>
    <div class="module-title">
      <div class="mt_line"></div>
      效果展示
      <span>Effect display</span>
    </div>
    <div class="content zhyx_img">
      <img src="./image/product_11_pic.jpg" alt="">
    </div>
    <div class="footer">
      <footerPage />
    </div>
  </div>
</template>

<script>
import footerPage from '../../components/footer/footer'
export default {
  components:{
    footerPage
  },
  data() {
    return {
      tagList:[
        {
          id:0,
          name:'营销监控系统'
        },
        {
          id:1,
          name:'运营决策系统'
        },
        {
          id:2,
          name:'实名客史管理系统'
        },
      ]
    }
  },
  methods: {
    handleScroll(e){
      console.log(e)
    }
  },
}
</script>

<style scoped lang='less'>
@import '../../assets/css/content.less';
.content_main{
  height: 100%;
  width: 100%;
  
  // background-image: url('../../assets/images/image1.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.bg{
  padding-bottom: 50px;
}
.banner{
  width: 100%;
  img{
    width: 100%;
    border: 0;
    display: block;
  }
}
.title{
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  h4{
    font-size: 30px;
  }
}
.module-title {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 26px;
  color: #0080cc;
  text-align: center;
  font-weight: normal;
  span {
    display: block;
    color: #818181;
    text-transform: uppercase;
    font-size: 19px;
    margin-top: 5px;
  }
}
.mt_line {
  width: 100%;
  height: 83px;
  margin-bottom: 20px;
  background: url('./../../../static/images/ai/btline.png') no-repeat center top;
}
.product_introduct{
  text-align: center;
  height: 80px;
  line-height: 80px;
  font-size: 2rem;
  background: #f5f5f5;
  margin: 0 10%;
  h4{
    font-size: 30px;
    line-height: 80px;
  }
}
.product_introduct_text{
  margin-top: 2rem;
  div{
    font-size: 18px;
    line-height: 40px;
    text-indent:3rem;
    margin-bottom: 20px;
  }
}
.product_z_system{
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .tag{
    width: 29.333%;
    margin: 1% 2%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 1px solid #cccccc;
    padding: 0 4%;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
      height: 90%;
    }
    span{
      width: 80%;
      display: inline-block;
      text-align: left;
    }
  }
}
.image_box{
  margin: 40px 0;
  img{
    width: 100%;
    margin: 20px 0;
  }
}
.zhyx_img{
  img{
    width: 100%;
  }
}
.footer{
  width: 100%;
  margin-top: 20px;
}
</style>
