<template>
  <div class="info content animate__animated animate__fadeInUp">
    <h4>鼎游信息，智慧旅游完整解决方案及产品提供商</h4>
    <p>
      深圳市鼎游信息技术有限公司（简称“鼎游信息”）成立于2004年，致力于为旅游机构信息化建设提供完整解决方案及产品，是国家高新技术企业；以旅委、旅游集团、景区为主要市场对象。总部位于深圳，在北京、武汉、成都、重庆、苏州、南昌、秦皇岛等设有办事处；参股张掖、西藏、河北、重庆等地公司，深耕目的地运营。
    </p>
    <p>
      鼎游信息自成立以来，秉承“鼎力景区，根植旅游”的经营理念，先后为全国32个行政区近5000家旅游机构提供信息化解决方案，积累了丰富的文旅行业信息化管理、建设、运营经验，能为旅游目的地智慧化建设提供完整的解决方案。
    </p>
    <p>
      鼎游信息与中国风景名胜区协会共同主持制定了国家级行业应用技术标准——中华人民共和国城镇建设行业标准《风景名胜区公共服务营销平台》，奠定了行业领军地位；此外公司还是国家“十一五”科技支撑计划重大项目“现代服务业共性技术支撑体系与应用示范工程”子课题“数字旅游”示范工程牵头单位。
    </p>
    <p>
      公司注重研发，取得软件著作权60余项，专利2项，并取得ISO9001管理体系认证，CMMI（软件能力成熟度集成模型）认证，信息系统集成及服务资质认证，安全技术防范系统设计、施工、维修认证等专业资质认证。
    </p>
    <p>
      公司是中国最大的景区B2B交易服务商，累计交易额超1500亿；服务人数超30亿人次；沉淀的游客信息超过5亿条。
    </p>
    <p>
      鼎游信息为超过12000家的渠道提供了分销服务；对接的交易结算接口超过50家，覆盖全国包括中国银行、工商银行、农业银行、建设银行等在内的所有的主流银行；实现与包括微信、支付宝、银联等在内的对接所有主流第三方支付平台的对接；
    </p>
    <p>
      通过沉淀的海量数据，鼎游信息为文旅行业提供专业的决策判断、预测分析、运营管理及趋势研究等文旅大数据服务。
    </p>
    <p>
      鼎游信息先后参与“一部手机游云南”、山西文旅智慧旅游平台、“一网知陕西，一机游三秦”、黑龙江森工集团北国游等省级文旅平台的建设。
    </p>
    <p>
      公司先后获得中国建研院CABR华夏建设科学技术奖、中国智慧城市论坛颁发中国智慧旅游杰出品牌企业奖、中国智慧旅游创新贡献奖、受邀参加首届旅游+互联网大会、目的地在线化科技创新奖、中国国家旅游·劲旅奖最佳智慧旅游方案服务商。
    </p>
    <p>
      公司更致力于将5G、人工智能、区块链等技术应用于文旅行业，率先将人脸识别技术应用于景区，奠定了鼎游在文旅行业内AI应用的领导地位。
    </p>
    <p>
      随着新基建在文旅行业的深度应用，文旅行业正在进行大规模的数字化转型。鼎游将加大研发投入，源源不断的将最新成果投入到实际应用中。
    </p>
    <p>
      行至半山不停顿，中流击水再出发！
    </p>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang='less' scoped>
@import url('../../../assets/css/content.less');
.info{
  padding-bottom: 60px;
  box-sizing: border-box;
  h4{
    display: block;
    width: 100%;
    text-align: center;
    height: 100px;
    line-height: 100px;
    font-size: 30px;
    font-weight: 600;
  }
  p{
    font-size: 18px;
    line-height: 40px;
    text-indent:3rem;
    margin-bottom: 10px;
  }
}
</style>