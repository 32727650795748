<template>
	<div class="header">
		<div class="ewm_box">
			<div class="ewm" @click="isShow = !isShow" @mouseenter="isShowMouseenter" @mouseleave="isShowMouseleave">
				<img src="../../assets/images/ewm.png" alt="">
				<div class="ewm_img" v-show="isShow">
					<img src="../../assets/images/gzh.jpg" alt="">
				</div>
			</div>
		</div>
		<div class="header_main ">
			<div class="logo" @click="$router.push('/')">
				<img src="../../assets/images/logo.png" alt="">
			</div>
			<ul>
				<li style="width:9%">
					<span @click.stop="$router.push('/home')">首页</span>
				</li>
				<li style="width:10%">
					<span @click.stop="goBuyTicket">门票预订</span>
				</li>
				<li style="width:13%" @mouseenter="mouseenter(2)" @mouseleave="mouseleave(2)">
					<span @click.stop="$router.push('/O2OpmsSystem')">产品</span>
					<div class="nav-more nav-more-product"
						 :class="productActive?'show-product-active':'close-product-active'">
						<ul>
							<li @click.stop="$router.push('/O2OpmsSystem')">O2O一体化系统</li>
							<li @click.stop="$router.push('/bigDataCenter')">大数据中心</li>
							<li @click.stop="$router.push('/cytSystem')">畅游通分销平台</li>
							<li @click.stop="$router.push('/zhyxSystem')">整合营销平台</li>
							<li @click.stop="$router.push('/zhgkPlatform')">综合管控平台</li>
							<li @click.stop="$router.push('/ittPlatform')">国际化营销平台</li>
							<li @click.stop="$router.push('/erxPlatform')">二销管理系统</li>
							<li @click.stop="$router.push('/pardwareProducts')">硬件产品</li>
						</ul>
					</div>
				</li>
				<li style="width:13%" @mouseenter="mouseenter(3)" @mouseleave="mouseleave(3)">
					<span @click.stop="$router.push('/smartScenicSpot')">方案</span>
					<div class="nav-more nav-more-soft"
						 :class="softActive?'show-product-active':'close-product-active'">
						<ul>
							<li @click.stop="$router.push('/smartScenicSpot')">景区</li>
							<li @click.stop="$router.push('/theatre')">剧场</li>
							<li @click.stop="$router.push('/themePark')">主题乐园</li>
							<li @click.stop="$router.push('/globalTourism')">全域旅游</li>
							<li @click.stop="$router.push('/iceAndSnowField')">冰雪场</li>
							<li @click.stop="$router.push('/museum')">博物馆</li>
							<li @click.stop="$router.push('/beautifulVillage')">美丽乡村</li>
							<li @click.stop="$router.push('/govManagement')">政府管理</li>
							<li @click.stop="$router.push('/prdAndEdu')">产教融合</li>
							<li @click.stop="$router.push('/digitalGroup')">文旅集团数字化转型</li>
						</ul>
					</div>
				</li>
				<li style="width:9%">
					<span @click.stop="$router.push('/casePage')">案例</span>
				</li>
				<li style="width:14%">
					<span @click="$router.push({path: '/ai'})">5G/人工智能</span>
				</li>
				<li style="width:15%">
					<span @click.stop="$router.push('/smartCulturalTour')">智慧文旅研究院</span>
				</li>
				<li style="width:10%" @mouseenter="mouseenter(6)" @mouseleave="mouseleave(6)">
					<span @click.stop="$router.push('/enterpriseIntroduction')">关于</span>
					<div class="nav-more nav-more-about"
						 :class="aboutActive?'show-product-active':'close-product-active'">
						<ul>
							<li @click.stop="$router.push('/enterpriseIntroduction')">关于鼎游</li>
							<li @click.stop="$router.push('/ectripRealtimeInfo')">鼎游资讯</li>
							<li @click.stop="$router.push('/contactUs')">联系我们</li>
						</ul>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			productActive: false,
			softActive: false,
			caseActive: false,
			aboutActive: false,
			isShow: false
		}
	},
	created() {
		this.productActive = false
		this.softActive = false
		this.caseActive = false
		this.aboutActive = false
	},
	methods: {
		goBuyTicket() {
			let _http = window.location.protocol
			let _url = 'http://ss.ectrip.com:9001/home/home.action'
			if(_http=='https') {
				_url = 'https://ss.ectrip.com:9001/home/home.action'
			}
			window.open(_url, '_self')
		},
		mouseenter(e) {
			switch (e) {
				case 2:
					this.productActive = true
					break;
				case 3:

					this.softActive = true
					break;
				case 4:
					this.caseActive = true
					break;
				case 6:
					this.aboutActive = true
					break;
				default:
					break;
			}
		},
		mouseleave(e) {
			switch (e) {
				case 2:
					this.productActive = false
					break;
				case 3:
					this.softActive = false
					break;
				case 4:
					this.caseActive = false
					break;
				case 6:
					this.aboutActive = false
					break;
				default:
					break;
			}
		},
		isShowMouseenter() {
			this.isShow = true
		},
		isShowMouseleave() {
			this.isShow = false
		}
	},
}
</script>

<style lang="less" scoped>
@import url('../../assets/css/content.less');
@import url('../../assets/css/animate.less');

.header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 8rem;
	color: #ffffff;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
	z-index: 2000;
	display: flex;
	align-items: center;

	.ewm_box {
		position: fixed;
		right: 0;
		top: 0;
		z-index: 3000;

		.ewm {
			width: 60px;
			height: 75px;
			position: relative;
			display: flex;
			justify-content: space-around;
			align-items: center;

			img {
				height: 40%;
			}

			.ewm_img {
				position: absolute;
				top: 70px;
				right: -5px;
				width: 100px;
				height: 100px;
				box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
				display: flex;
				justify-content: space-around;
				align-items: center;
				z-index: 3000;
				background: #ffffff;

				img {
					width: 90%;
					height: 90%;
					display: block;
					border: 0;
				}
			}
		}
	}

	.header_main {
		height: 100%;
		width: 100%;
		padding: 0 4%;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		.logo {
			height: 100%;
			width: 12%;
			display: flex;
			justify-content: space-around;
			align-items: center;

			img {
				width: 100%;
				border: 0;
			}
		}

		ul {
			flex: 1;
			width: 100%;
			height: 100%;
			align-items: center;
			z-index: 2000;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 0 !important;

			li:hover {
				background-color: #35c0ff;
				color: #ffffff;
			}

			li {
				display: inline-block;
				height: 100%;
				margin: 0 auto;
				text-align: center;
				font-size: 2.2rem;
				font-weight: 200;
				box-sizing: border-box;
				cursor: pointer;
				position: relative;
				z-index: 2000;
				color: #333333;

				span {
					display: flex;
					justify-content: space-around;
					align-items: center;
					width: 100%;
					height: 100%;
					z-index: 2000;
					font-weight: 700;
				}

				.nav-more {
					position: absolute;
					top: 75px;
					left: 0%;
					width: 100%;
					background-color: #ffffff;
					border-radius: 0 0 8px 8px;
					z-index: -1000;
					box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

					ul {
						display: flex;
						flex-direction: column;
						height: 100%;
						width: 100%;
						z-index: 1000;
						overflow: hidden;

						li {
							display: flex;
							justify-content: space-around;
							align-items: center;
							padding: 0;
							margin: 4px;
							width: 100%;
							// height: 40px;
							text-align: center;
							// line-height: 40px;
							font-weight: normal;
							font-size: 1.75rem;
							line-height: 2;
							color: #000;
							z-index: 1000;
							box-sizing: border-box;
						}

						li:hover {
							background-color: #35c0ff;
							color: #FFF;
						}
					}
				}

				.nav-more-product {
					height: 390px;
				}

				.nav-more-soft {
					height: 480px;
				}

				.nav-more-case {
					height: 240px;
				}

				.nav-more-about {
					height: 160px;
				}
			}
		}
	}
}
</style>
