<template>
  <div class="content_main" @scroll="handleScroll">
    <div class="banner animate__animated animate__bounceInLeft">
      <img src="../../assets/images/n-banner02.jpg" alt="">
    </div>
    <div class="bg animate__animated animate__fadeInUp">
      <div class="module-title">
        智慧文旅研究院
        <span>Smart culture and Tourism Research Institute</span>
      </div>
      <div class="module-title">
        <div class="mt_line"></div>
        智慧景区
      </div>
      <div class="product_introduct_text content">
        <div>
          <img src="./images/zhihuilvyou.gif" alt="">
        </div>
        <div>
          <h4>介绍</h4>
          <span>
            风景名胜区智慧景区建设是风景名胜区智慧旅游服务发展的技术基础。“十五”期间，在国家高技术研究发展计划（“863计划”）和科技攻关计划支持下，住宅与城乡建设部（原建设部）在187个国家重点风景名胜区开展了监管信息系统建设和动态遥感监测工作，有力地推动了遥感、GIS技术在行业范围内的普及应用；并在九寨沟和黄山风景名胜区开展了“智慧景区”示范工程建设。包括景区自身的办公自动化，以大屏幕、触摸屏等多种技术手段为载体、以多媒体 和虚拟现实（VR）等多种技术手段为表现形式的旅游资讯服务，基于视频监控和GPS技术的游客安全监控和指挥调度，旅游目的地资源营销服务等等，取得了良好的社会和经济效益。
          </span>
        </div>
      </div>
      <div class="module-title">
        <div class="mt_line"></div>
        智慧旅游
      </div>
      <div class="info content">
        <p>
          智慧旅游系统遵照智慧城市总体规划的要求，整合当前国际先进的信息化技术、管理理念和成果借鉴，并结合旅游行业业务现状、发展趋势及对信息化建设的需求等多方面进行综合评估、分析、研究，最终形成智慧旅游服务系统设计的依据。平台涵盖公众服务，行业服务和管理服务三大服务，涉及到吃、住、行、游、购、娱等六大领域，建立以旅客为中心的全程服务体验，有效提升旅客满意度、旅游形象、旅行服务品质，从而提升城市的综合竞争力、公共服务能力和服务形象，为城市和所属区域的发展创造巨大社会效益和经济效益。
        </p>
        <p>
          <span>食：</span>智慧旅游中的“食”是指利用云计算、物联网等新技术，通过互联网/移动互联网，借助便携的终端上网设备，为游客提供旅游目的地各种餐饮场所的有关资讯、预订、购买、支付等服务，包括餐厅、酒吧、饮品店、特色小吃等。
        </p>
        <p>
          <span>住：</span>智慧旅游中的“住”是指利用云计算、物联网等新技术，通过互联网/移动互联网，借助便携的终端上网设备，为游客提供旅游目的地各类住宿设施的有关资讯、预订、购买、支付、反馈等服务，包括饭店、酒店、客栈、民宿、露营基地、房车基地等。
        </p>
        <p>
          <span>行：</span>智慧旅游中的“行”是指利用云计算、物联网等新技术，通过互联网/移动互联网，借助便携的终端上网设备，为游客提供出行前的大交通行程规划、交通预订、购买、支付等服务，出行中的小交通有关资讯、预订、支付、等服务。
        </p>
        <p>
          <span>游：</span>智慧旅游中的“游”是指利用云计算、物联网等新技术，通过互联网/移动互联网，借助便携的终端上网设备，为游客提供旅游目的地各类型目的地资讯、导游、导览、语音讲解、虚拟旅游体验等服务。
        </p>
        <p>
          <span>购：</span>智慧旅游中的“购”是指利用云计算、物联网等新技术，通过互联网/移动互联网，借助便携的终端上网设备，为游客提供旅游目的地纪念品、工艺美术品、土产、农牧制品及当地生活用品(便利、特色)等当地特色产品的资讯、预订、购买、支付、邮寄等服务。
        </p>
        <p>
          <span>娱：</span>智慧旅游中的“娱”是指利用云计算、物联网等新技术，通过互联网/移动互联网，借助便携的终端上网设备，为游客提供旅游目的地游乐场所的资讯、预订、购买、支付、邮寄等服务，包括健身房、泳池、网球场、舞厅、高尔夫等。
        </p>
      </div>
      <div class="image content">
        <img src="./images/img01.jpg" alt="">
        <img src="./images/img02.jpg" alt="">
      </div>
    </div>
    <div class="footer">
      <footerPage />
    </div>
  </div>
</template>

<script>
import footerPage from '../../components/footer/footer'
export default {
  components:{
    footerPage
  },
  data() {
    return {
      tagList:[
        {
          id:0,
          name:'营销监控系统'
        },
        {
          id:1,
          name:'运营决策系统'
        },
        {
          id:2,
          name:'实名客史管理系统'
        },
      ]
    }
  },
  methods: {
    handleScroll(e){
      console.log(e)
    }
  },
}
</script>

<style scoped lang='less'>
@import '../../assets/css/content.less';
.content_main{
  height: 100%;
  width: 100%;
  
  // background-image: url('../../assets/images/image1.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.bg{
  padding-bottom: 50px;
}
.banner{
  width: 100%;
  img{
    width: 100%;
    border: 0;
    display: block;
  }
}
.title{
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  h4{
    font-size: 30px;
  }
}
.module-title {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 26px;
  color: #0080cc;
  text-align: center;
  font-weight: normal;
  span {
    display: block;
    color: #818181;
    text-transform: uppercase;
    font-size: 19px;
    margin-top: 5px;
  }
}
.mt_line {
  width: 100%;
  height: 83px;
  margin-bottom: 20px;
  background: url('./../../../static/images/ai/btline.png') no-repeat center top;
}
.product_introduct{
  text-align: center;
  height: 80px;
  line-height: 80px;
  font-size: 2rem;
  background: #f5f5f5;
  margin: 0 10%;
  h4{
    font-size: 30px;
    line-height: 80px;
  }
}
.product_introduct_text{
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  // align-items: center;
  div:first-child{
    width: 50%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    img{
      width: 80%;
    }
  }
  div:last-child{
    width: 50%;
    h4{
      color: #3185c0;
      line-height: 50px;
      font-size: 24px;
      font-weight: 600;
    }
    span{
      line-height: 50px;
      font-size: 16px;
      text-indent: 0em;
    }
  }
}
.product_z_system{
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .tag{
    width: 29.333%;
    margin: 1% 2%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 1px solid #cccccc;
    padding: 0 4%;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img{
      height: 90%;
    }
    span{
      width: 80%;
      display: inline-block;
      text-align: left;
    }
  }
}
.image_box{
  margin: 40px 0;
  img{
    width: 100%;
    margin: 20px 0;
  }
}
.zhyx_img{
  img{
    width: 100%;
  }
}
.footer{
  width: 100%;
  margin-top: 20px;
}
.info{
  padding-bottom: 60px;
  box-sizing: border-box;
  p{
    font-size: 18px;
    line-height: 40px;
    text-indent:3rem;
    margin-bottom: 10px;
    span{
      font-weight: 600;
    }
  }
}
.image{
  img{
    display: block;
    width: 100% !important;
    margin-bottom: 10px;
  }
}
</style>
