<template>
	<div class="content_main" @scroll="handleScroll">
		<div class="banner animate__animated animate__bounceInLeft">
			<img src="./images/bannerGov.jpg" alt="">
		</div>
		<div class="animate__animated animate__fadeInUp">
			<div class="module-title">
				政府管理
				<div class="mt_line"></div>
			</div>
			<div class="programme content">

				<div class="programme_box">
					<div>
						<img src="./images/iconsNote_08.png" alt="">
					</div>
					<div>大数据中心
					</div>
				</div>
				<div class="programme_box">
					<div>
						<img src="./images/iconsNote_09.png" alt="">
					</div>
					<div>旅游产业监测平台
					</div>
				</div>
				<div class="programme_box">
					<div>
						<img src="./images/iconsNote_10.png" alt="">
					</div>
					<div>应急指挥调度中心
					</div>
				</div>
				<div class="programme_box">
					<div>
						<img src="./images/iconsNote_11.png" alt="">
					</div>
					<div>文旅投诉管理系统
					</div>
				</div>
				<div class="programme_box">
					<div>
						<img src="./images/iconsNote_12.png" alt="">
					</div>
					<div>口碑诚信管理系统
					</div>
				</div>
				<div class="programme_box">
					<div>
						<img src="./images/iconsNote_13.png" alt="">
					</div>
					<div>舆情监测分析系统
					</div>
				</div>
				<div class="programme_box">
					<div>
						<img src="./images/iconsNote_14.png" alt="">
					</div>
					<div>文旅抽样调查系统
					</div>
				</div>
				<div class="programme_box">
					<div>
						<img src="./images/iconsNote_15.png" alt="">
					</div>
					<div>旅游团队监管服务平台
					</div>
				</div>
			</div>
		</div>

		<div>
			<footerPage/>
		</div>
	</div>
</template>

<script>
import hexagon from '../../components/hexagon/hexagon'
import footerPage from '../../components/footer/footer'

export default {
	components: {
		hexagon,
		footerPage
	},
	data() {
		return {
			hexagonList: [
				{
					id: 0,
					title: '广东丹霞山',
					srcUrl: require('./images/jq-dxs.png')
				},
				{
					id: 1,
					title: '神农架',
					srcUrl: require('./images/jq-snj.jpg')
				},
				{
					id: 2,
					title: '武夷山',
					srcUrl: require('./images/jq-wys.jpg')
				},
				{
					id: 3,
					title: '张掖丹霞',
					srcUrl: require('./images/jq-zydx.jpg')
				}
			]
		}
	},
	methods: {
		handleScroll(e) {
			let scrollTop = e.srcElement.scrollTop

		}
	},
}
</script>

<style lang='less' scoped>
@import url('../../assets/css/content.less');

.content_main {
	height: 100%;
	width: 100%;
	overflow-y: auto;
}

.banner {
	width: 100%;

	img {
		width: 100%;
		border: 0;
		display: block;
	}
}

.module-title {
	margin-top: 40px;
	margin-bottom: 40px;
	font-size: 26px;
	color: #0080cc;
	text-align: center;
	font-weight: normal;
}

.mt_line {
	width: 100%;
	height: 83px;
	margin-bottom: 20px;
	background: url('./../../../static/images/ai/btline.png') no-repeat center top;
}

.programme {
	margin-top: 2rem;
	width: 100%;
	display: flex;
	border-radius: 10px;
	flex-wrap: wrap;
	align-items: center;

	.programme_box {
		width: 20%;
		margin: 5% 2.5%;
		height: 60px;
		line-height: 60px;
		text-align: center;
		border-radius: 6px;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
		font-size: 18px;
		box-sizing: border-box;
		cursor: pointer;
		position: relative;

		div:first-child {
			position: absolute;
			width: 100px;
			height: 100px;
			border-radius: 50%;
			left: -50px;
			top: -50px;
			display: flex;
			justify-content: space-around;
			align-items: center;

			img {
				width: 60%;
			}
		}
	}
}

.hexagon_box {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-bottom: 20px;
}
</style>
