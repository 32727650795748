<template>
  <div class="content_main" @scroll="handleScroll">
    <div class="banner animate__animated animate__bounceInLeft">
      <img src="./images/qyly.jpg" alt="">
    </div>
    <div class="animate__animated animate__fadeInUp">
      <div class="module-title">
        全域旅游解决方案
        <div class="mt_line"></div>
      </div>
      <div class="programme content">
        <div class="programme_box">
          <div>
            <img src="./images/O2Oyth.png" alt="">
          </div>
          <div>
            O2O一体化票务系统
          </div>
        </div>
        <div class="programme_box">
          <div>
            <img src="./images/xcx.png" alt="">
          </div>
          <div>
            小程序
          </div>
        </div>
        <div class="programme_box">
          <div>
            <img src="./images/wsc.png" alt="">
          </div>
          <div>
            微商城
          </div>
        </div>
        
        <div class="programme_box">
          <div>
            <img src="./images/APP.png" alt="">
          </div>
          <div>
            APP
          </div>
        </div>
        <div class="programme_box">
          <div>
            <img src="./images/zhgk.png" alt="">
          </div>
          <div>
            可视化综合管控平台
          </div>
        </div>
        <div class="programme_box">
          <div>
            <img src="./images/dsjfxzx.png" alt="">
          </div>
          <div>
            大数据分析系统
          </div>
        </div>
      </div>
      
    </div>
    <div>
      <div class="module-title">
        部分案例
        <div class="mt_line"></div>
      </div>
      <div class="content hexagon_box">
        <hexagon v-for="item in hexagonList" :key="item.id" :title="item.title" :imageSrc="item.srcUrl" />
      </div>
    </div>
    <div>
      <footerPage />
    </div>
  </div>
</template>

<script>
import hexagon from '../../components/hexagon/hexagon'
import footerPage from '../../components/footer/footer'
export default {
  components:{
    hexagon,
    footerPage
  },
  data() {
    return {
      hexagonList:[
        {
          id:0,
          title:'阿坝旅游',
          srcUrl:require('./images/qyly-ably.jpg')
        },
        {
          id:1,
          title:'一机游三秦',
          srcUrl:require('./images/qyly-yjysq.jpg')
        },
        {
          id:2,
          title:'云游山西',
          srcUrl:require('./images/qyly-yysx.jpg')
        },
        {
          id:3,
          title:'黑龙江北国游',
          srcUrl:require('./images/qyly-yysx.jpg')
        }
      ]
    }
  },
  methods: {
    handleScroll(e){
      console.log(e)
    }
  },
}
</script>

<style lang='less' scoped>
@import url('../../assets/css/content.less');
.content_main{
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.banner{
  width: 100%;
  img{
    width: 100%;
    border: 0;
    display: block;
  }
}
.module-title {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 26px;
  color: #0080cc;
  text-align: center;
  font-weight: normal;
}
.mt_line {
  width: 100%;
  height: 83px;
  margin-bottom: 20px;
  background: url('./../../../static/images/ai/btline.png') no-repeat center top;
}
.programme{
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  border-radius: 10px;
  flex-wrap: wrap;
  align-items: center;
  .programme_box{
    width: 29.333%;
    margin: 5% 2% 5% 2%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    font-size: 18px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    div:first-child{
      position: absolute;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      left: -50px;
      top: -50px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      img{
        width: 60%;
      }
    }
  }
}
.hexagon_box{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 20px;
}
</style>
