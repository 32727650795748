<template>
  <div class="info content animate__animated animate__fadeInUp">
    <h4>董事长致辞</h4>
    <p style="color:red;">
      我们的心愿是以最出色的产品和服务，成为智慧文旅首选的业务合作伙伴。
    </p>
    <p>
      鼎游在成长的道路上，一直伴随着辛勤的汗水。
    </p>
    <p>
      记得两年前，我们在做黄山项目的时候，为了在一个月的时间里面完成系统从无到有的构建，小组成员每天往返九十公里，只睡不足五个小时，昼夜奋战，以致当平台搭建基本完成时，有的同事已经虚脱。黄山是世界级的景区，当我们的项目小组解除半年多的封闭开发离开的时候，没有人有时间游览过任何一处景点。天下恋庐山，项目组成员下山时，仍然不知庐山真面目。前往“童话世界”九寨沟的路是崎岖不平的，我们这条路上跑了数十趟。
    </p>
    <p>
      当大家远离家乡、远离公司，在异地超强度工作的时候，支撑起这一切的不再是报酬的多少，而是一种责任感和共同的愿景。因为我们知道其他的同事也在不同的岗位上同样付出着。辛勤的付出理应得到快乐的回报。我们感到前所未有的责任。
    </p>
    <p>
      如果我们不能正确地认知行业的发展趋势和景区的需求，我们的付出就得不到景区的认可；如果我们把握了正确的业务方向，那我们可能获得加倍的回报。 我们一刻也不敢放松，我们一直在思考和探索。值得庆幸的是，我们在各个方面都取得了长足的进步和突破。
    </p>
    <p>
      我们将企业的核心竞争力定义在对智慧文旅业务的深刻把握上，构建了强大的知识和顾问合作背景。在我们新推出的产品中，您会感受到这一点。我们一直认为，我们给智慧文旅提供的核心价值并不只是一个稳定好用的系统软件，更重要的是承载应用经验和管理技术的软件解决方案。我们培植自己独特的增值服务能力，贴近景区当前和发展中的需求。您将会在我们的增值服务计划中体验到这一点。
    </p>
    <p>
      鼎游希望给智慧文旅带来真正的资源管理信息化体验，让智慧文旅信息化的过程变得透明和井井有条。在接下来的日子里，相信您更会看到这一点。
    </p>
    <p>
      在景区面前，公司全体员工一直怀着一颗感恩的心，没有景区给我们参与实践的机会，就没有公司蓬勃发展的今天。
    </p>
    <p>
      如果我们一直在智慧文旅信息化的道路上艰苦付出，我们会收获成功。因为我们始终相信一条真理，从实践中来，到实践中去。
    </p>
    <p>
      做最好的智慧文旅管理软件，以我们创造的价值，获得文旅行业的认同，这是我们的心愿！
    </p>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang='less' scoped>
.info{
  padding-bottom: 60px;
  box-sizing: border-box;
  h4{
    display: block;
    width: 100%;
    text-align: center;
    height: 100px;
    line-height: 100px;
    font-size: 30px;
    font-weight: 600;
  }
  p{
    font-size: 18px;
    line-height: 40px;
    text-indent:3rem;
    margin-bottom: 10px;
  }
}
</style>