<template>
  <div class="main">
    <div class="top"></div>
    <div class="ny_lw">
      <div class="ny_k_cn animate__animated animate__bounceInUp">
        <div class="ny_bt">
          部分合作5A景区
          <span>Cooperation scenic spot</span>
        </div>
        <div class="ny_sm">截止至2020年6月，鼎游信息为全国32个行政区近<span style="color:#f00; font-weight: bold; font-size: 30px;"> 5000 </span>家旅游机构提供信息化解决方案。</div>
        <div class="case_p3">
          <div id="pic" class="big-img"><img :src="src" alt="" style="width: 100%; height: 100%;"></div>
          <a class="case_p3p" @click="getTicketDetail(0)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/0.png">
            <p>武夷山</p>
          </a>
          <a class="case_p3p" @click="getTicketDetail(1)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/1.png">
            <p>神农架</p>
          </a>
          <a class="case_p3p" @click="getTicketDetail(2)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/2.png">
            <p>婺源</p>
          </a>
          <a class="case_p3p" @click="getTicketDetail(3)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/3.png">
            <p>重庆武隆</p>
          </a>
          <a class="case_p3p" @click="getTicketDetail(4)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/4.png">
            <p>张掖丹霞</p>
          </a>
          <div class="clearBoth hidden-md"></div>
          <a class="case_p3p" @click="getTicketDetail(5)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/5.png">
            <p>锦绣中华</p>
          </a>
          <a class="case_p3p" @click="getTicketDetail(6)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/6.png">
            <p>海南呀诺达</p>
          </a>
          <a class="case_p3p" @click="getTicketDetail(7)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/7.png">
            <p>广东丹霞山</p>
          </a>
          <a class="case_p3p" @click="getTicketDetail(8)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/8.png">
            <p>张家界武陵源</p>
          </a>
          <div class="clearBoth hidden-md"></div>
          <a class="case_p3p" @click="getTicketDetail(9)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/9.png">
            <p>阿尔山</p>
          </a>
          <a class="case_p3p" @click="getTicketDetail(10)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/10.png">
            <p>克什克腾旗石阵</p>
          </a>
          <a class="case_p3p" @click="getTicketDetail(11)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/11.png">
            <p>亚布力</p>
          </a>
          <a class="case_p3p" @click="getTicketDetail(12)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/12.png">
            <p>湖北省博物馆</p>
          </a>
          <a class="case_p3p" @click="getTicketDetail(13)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/13.png">
            <p>新疆吐鲁番</p>
          </a>


          <div class="clearBoth hidden-md"></div>
          <a class="case_p3p" @click="getTicketDetail(14)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/14.png">
            <p>镜泊湖</p>
          </a>
          <a class="case_p3p" @click="getTicketDetail(15)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/15.png">
            <p>罗浮山</p>
          </a>
          <a class="case_p3p" @click="getTicketDetail(16)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/16.png">
            <p>瘦西湖</p>
          </a>
          <a class="case_p3p" @click="getTicketDetail(17)">
            <div class="case_p3_k"></div>
            <img class="case_p3_img" src="./../../../static/images/case/17.png">
            <p>九寨沟</p>
          </a>
        </div>
      </div>
      <div class="ny_kw" id="ny_kg">
        <div class="ny_k_cn">
          <div class="ny_bt">部分合作文旅集团<span>Payment partners</span></div>
          <div class="case_p4">
            <a class="case_p4p">
              <div class="case_p4_k"></div>
              <img class="case_p4_img" src="./../../../static/images/case/logo0.png">
            </a>
            <a class="case_p4p">
              <div class="case_p4_k"></div>
              <img class="case_p4_img" src="./../../../static/images/case/logo1.png">
            </a>
            <a class="case_p4p">
              <div class="case_p4_k"></div>
              <img class="case_p4_img" src="./../../../static/images/case/logo2.png">
            </a>
            <a class="case_p4p">
              <div class="case_p4_k"></div>
              <img class="case_p4_img" src="./../../../static/images/case/logo3.png">
            </a>
            <div class="clearBoth hidden-md"></div>
            <a class="case_p4p">
              <div class="case_p4_k"></div>
              <img class="case_p4_img" src="./../../../static/images/case/logo4.png">
            </a>
            <a class="case_p4p">
              <div class="case_p4_k"></div>
              <img class="case_p4_img" src="./../../../static/images/case/logo5.png">
            </a>
            <a class="case_p4p">
              <div class="case_p4_k"></div>
              <img class="case_p4_img" src="./../../../static/images/case/logo6.png">
            </a>
            <a class="case_p4p">
              <div class="case_p4_k"></div>
              <img class="case_p4_img" src="./../../../static/images/case/logo7.png">
            </a>
            <a class="case_p4p">
              <div class="case_p4_k"></div>
              <img class="case_p4_img" src="./../../../static/images/case/logo8.png">
            </a>
          </div>
          <div class="clearBoth"></div>
        </div>
      </div>
    </div>
    <div>
      <footerPage />
    </div>
  </div>
</template>

<script>
  import footerPage from '../../components/footer/footer'
  import {WOW} from 'wowjs'
  export default {
    components:{
      footerPage
    },
    name: "case",
    data() {
      return {
        show: false,
        src: ''
      }
    },
    mounted() {
      let wow0 = new WOW({
        boxClass: 'wow0',
        animateClass: 'animate__animated animate__bounceInLeft',
        offset: 0,
        mobile: false
      })
      let wow1 = new WOW({
        boxClass: 'wow1',
        animateClass: 'animate__animated animate__bounceInRight',
        offset: 0,
        mobile: false
      })
      wow0.init();
      wow1.init();
      // $(".case_p3p").hover(function(){
      //   let obj = $("#pic")
      //   obj.show()
      //   obj.find("img").attr('src', $(this).find("img").attr('src'))
      // },function(){
      //   $("#pic").hide()
      //   $("#pic").find("img").attr('src', '')
      // });
    },
    methods: {
      getTicketDetail(id) {
        // window.location.href = this.$config.companyUrl[id]
        window.open(this.$config.companyUrl[id],'_blank')
      }
    }
  }
</script>

<style  lang='less' scoped>
  @import url('./../../../static/css/bootstrap.css');
  .big-img {
    width: 400px;
    height: 300px;
    position: absolute;
    z-index: 199;
    display: none;
    border: 10px solid #12b1e7;
    border-radius: 20px;
    top: 50%;
    left: 5%;
    margin-top: -150px;    /* 高度的一半 */
  }
  .main {
    width: 100%;
    height: 100%;

    .top {
      width: 100%;
      height: 40vh;
      background-image: url('./../../../static/images/case/n-banner01.jpg');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    .ny_lw {
      width: 100%;
      margin: 0 auto;

      .ny_k_cn {
        margin: 0 auto;
        padding: 60px 0;
      }

      .ny_bt {
        font-size: 26px;
        color: #0080cc;
        text-align: center;
        margin-bottom: 35px;

        span {
          display: block;
          color: #818181;
          text-transform: uppercase;
          font-size: 19px;
          margin-top: 5px;
        }
      }

      .ny_sm {
        text-align: center;
        width: 100%;
        font-size: 19px;
        color: #565656;
        line-height: 32px;
      }

      #ny_kg {
        background-color: #f3f3f3;

        .ny_k_cn {
          max-width: 1200px;
          margin: 0 auto;
          padding: 60px 0;

          .ny_bt {
            font-size: 26px;
            color: #0080cc;
            text-align: center;
            margin-bottom: 35px;
          }

          .case_p3, .case_p4 {
            width: 100%;
            text-align: center;
            position: relative;
            .case_p4p {
              width: 200px;
              height: 174px;
              margin: 5px 15px;
              display: inline-block;
              position: relative;

              .case_p4_k {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 103;
                background: url('./../../../static/images/case/case4k1.png') no-repeat top center;
              }
            }
            .case_p4p:hover .case_p4_k{
              background:url('./../../../static/images/case/case4k2.png') no-repeat top center;
            }
          }

          .case_p3p *, .case_p4p * {
            transition: all 0.8s;
          }
        }
      }
    }
  }
  .case_p3p{
    width:200px;
    height:200px;
    margin:5px 10px;
    display:inline-block;
    position:relative;
    img {
      width: 180px;
      height: 170px;
    }
    p{
      line-height: 30px;
    }
  }
  .case_p3p *,.case_p4p *{-webkit-transition:all 0.8s;-moz-transition:all 0.8s;-o-transition:all 0.8s;-ms-transition:all 0.8s;transition:all 0.8s;}
  .case_p3_k{
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    z-index:102;
    overflow: hidden;
    background:url('./../../../static/images/case/case4k1.png') no-repeat top center;
    box-shadow: 0 4px 12px rgba(0,0,0, .1);
  }
  .case_p3p:hover .case_p3_k{
    background:url('./../../../static/images/case/case4k2.png') no-repeat top center;
    box-shadow: 0 4px 9px rgba(0, 0, 0, 0.3);
    top: -3px;
  }
  .case_p3, .case_p4{ width:100%; text-align:center;}

</style>
