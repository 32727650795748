<template>
  <div class="swiper_box" >
    <swiper :options="swiperOption" ref="firstSwiper">
      <swiper-slide style="margin:0;width: 100%;">
        <img class="img" src="../images/h-banner01.jpg" alt="">
      </swiper-slide>
      <!-- <swiper-slide style="margin:0;width: 100%;">
        <img class="img" src="../images/h-banner03.jpg" alt="">
      </swiper-slide> -->
      <swiper-slide style="margin:0;width: 100%;">
        <img class="img" src="../images/h-banner05.jpg" alt="">
      </swiper-slide>
      <!-- <swiper-slide style="margin:0;width: 100%;">
        <img class="img" src="../images/h-banner07.jpg" alt="">
      </swiper-slide> -->
      <swiper-slide style="margin:0;width: 100%;">
        <img class="img" src="../images/h-banner10.jpg" alt="">
      </swiper-slide>
      <swiper-slide style="margin:0;width: 100%;">
        <img class="img" src="../images/h-banner12.jpg" alt="">
      </swiper-slide>
      <swiper-slide style="margin:0;width: 100%;">
        <img class="img" src="../images/h-banner13.jpg" alt="">
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        autoplay: {
          delay: 3000,
          disableOnInteraction: true // 手动切换之后继续自动轮播
        },
        loop: true,
      },
      img01:'url("'+require('../images/h-banner01.jpg')+'")',
      // img01:'url("'+require('../../../assets/images/banner.jpg')+'")',
      // img02:'url("'+require('../images/h-banner03.jpg')+'")',
      img03:'url("'+require('../images/h-banner05.jpg')+'")',
      // img04:'url("'+require('../images/h-banner07.jpg')+'")',
      img05:'url("'+require('../images/h-banner10.jpg')+'")',
      img06:'url("'+require('../images/h-banner12.jpg')+'")',
      img07:'url("'+require('../images/h-banner13.jpg')+'")',
    }
  },
  computed: {
    swiper () {
      return this.$refs.firstSwiper.swiper
    }
  },
  mounted () {
    
  },
  watch: {
    screenWidth (val) {
      this.screenWidth = val
    }
  }
}
</script>

<style lang='less' scoped>

.swiper_box{
  width: 100%;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
  .zx_box{
    flex: 1;
  }
}
.swiper_box>.swiper-container{
  height: 100% !important;
}
.swiper_box>.swiper-container>.swiper-wrapper{
  height: 100% !important;
}
.img{
  display: block;
  border: 0;
  width: 100%;
}
</style>
