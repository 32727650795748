<template>
  <div class="content" :class="thirdActive?'animate__animated animate__fadeInUp':''">
    <div class="title">
      <h2>应用场景</h2>
      <span>Application scenario</span>
    </div>
    <div class="main">
      <div class="main_center">
        <hexagon :routerStr="'/smartScenicSpot'" :title="'智慧景区'" :titleBottom="'智慧景区'" :imageSrc="imageSrcOne" />
        <hexagon :routerStr="'/globalTourism'" :title="'全域旅游'" :titleBottom="'全域旅游'" :imageSrc="imageSrcTwo" />
        <hexagon :routerStr="'/iceAndSnowField'" :title="'冰雪场'" :titleBottom="'全域旅游'" :imageSrc="imageSrcSix" />
      </div>
      <div class="main_last">
        <hexagon :routerStr="'/theatre'" :title="'影剧场'" :titleBottom="'影剧场'" :imageSrc="imageSrcthree" />
        <hexagon :routerStr="'/themePark'" :title="'主题乐园'" :titleBottom="'主题乐园'" :imageSrc="imageSrcfour" />
        <hexagon :routerStr="'/museum'" :title="'博物馆'" :titleBottom="'博物馆'" :imageSrc="imageSrcfive" />
      </div>
    </div>
  </div>
</template>

<script>
import hexagon from '../../../components/hexagon/hexagon'
export default {
  components:{
    hexagon
  },
  props:{
    thirdActive:{
      type:Boolean
    }
  },
  data() {
    return {
      imageSrcSix:require('../images/ybl.jpg'),
      imageSrcOne:require('../images/jq.jpg'),
      imageSrcTwo:require('../images/qyly.jpg'),
      imageSrcthree:require('../images/yjc.jpg'),
      imageSrcfour:require('../images/ztly.jpg'),
      imageSrcfive:require('../images/bwg.jpg')
    }
  },
  methods: {
    to(e){
      alert(e)
    }
  },
}
</script>

<style>
  @import '../../../assets/css/content.less';
</style>

<style scoped lang='less'>
.title{
  margin-top: 2%;
  text-align: center;
  width: 100%;
  color: #ffffff;
}
.title>h2{
  font-size: 3rem;
}
.title>span{
  font-size: 2rem;
}
.main{
  width: 100%;
  height: 100%;
  padding-top: 3%;
  box-sizing: border-box;
  .main_center{
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
    margin-top: -3%;
  }
  .main_last{
    margin-top: 5%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-sizing: border-box;
  }
}

</style>
