<template>
  <div class=" ">
    <div class="banner animate__animated animate__bounceInLeft">
      <img src="./images/banner.jpg" alt="">
    </div>
    <div class="module-title animate__animated animate__fadeInUp">
      动态
      <div class="mt_line"></div>
      <div class="info content">
        <div class="news_item" v-for="(item, index) in newsList" :key="index" @click="toNewsInfo(item.newsid)">
          <div>{{item.title}}</div>
          <div>[{{item.datetime}}]</div>
        </div>
        <div class="page_box">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="currentPage"
            :total="100">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="footer">
      <footerPage />
    </div>
  </div>
</template>

<script>
import footerPage from '../../components/footer/footer'
import axios from 'axios'
export default {
  components:{
    footerPage
  },
  data() {
    return {
      newsList:[],
      page:1,
      typeid:1
    }
  },
  created() {
    this.getNewsList()
  },
  methods: {
    getNewsList(){
      $.ajax({
        url:'/article/moreJson.do',
        data:{
          "typeid":this.typeid,
          "pager.offset":this.page
        },
        dataType:'jsonp',
        type:'get',
        cache:false,
        timeout:5000,
        success:(res)=>{
          if(res.code == 'success'){
            this.newsList = res.list
          }
        },
        error:(err)=>{
          console.log(err)
        }
      })
    },
    currentPage(val){
      this.page = val
      this.getNewsList()
    },
    toNewsInfo(e){
      this.$router.push('/realtimeInfo?id='+e)
    }
  },
}
</script>

<style lang='less' scoped>
@import url('../../assets/css/content.less');
.banner{
  width: 100%;
  img{
    width: 100%;
    border: 0;
    display: block;
  }
}
.info{
  min-height: 400px;
  padding-bottom: 40px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  .news_item{
    font-size: 16px;
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    div:first-child{
      text-align: left;
      width: 70%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
    div:first-child:hover{
      color: red;
    }
  }
  .page_box{
    width: 100%;
    text-align: right;
    margin-top: 10px;
    margin-right: 10px;
  }
}
.footer{
  width: 100%;
}
.mt_line {
  width: 100%;
  height: 83px;
  margin-bottom: 20px;
  background: url('./../../../static/images/ai/btline.png') no-repeat center top;
}
.module-title {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 26px;
  text-align: center;
}

</style>
