<template>
  <div class="main" @scroll="handleScroll">
    <!-- <div class="top animate__animated animate__bounceInLeft"></div> -->
    <div class="banner animate__animated animate__bounceInLeft">
      <img src="../../assets/images/n-banner02.jpg" alt="">
    </div>
    <div class="module">
      <div class="wrapper">
        <div class="module-title">
          <div class="mt_line"></div>
        </div>

        <div class="ny_kw">
          <div class="container">
            <div class="row">
              <div class="col-sm-8" >
                <div class="pro1_pic animate__animated animate__bounceInLeft">
                  <img src="./image/zj4.png" alt="">
                </div>
              </div>
              <div class="col-sm-4" >
                <div class="pro1_txt animate__animated animate__bounceInRight">
                  <div class="pro1_bt ">
                    三辊闸
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div class="module">
      <div class="wrapper">
        <div class="module-title">
          <div class="mt_line"></div>
        </div>

        <div class="ny_kw" v-show="yz">
          <div class="container">
            <div class="row">
              <div class="col-sm-4" >
                <div class="pro1_txt"
                  :class="yz?'animate__animated animate__bounceInLeft':''"
                  >
                  <div class="pro1_bt ">
                    翼闸
                  </div>
                </div>
              </div>
              <div class="col-sm-8" >
                <div class="pro1_pic"
                  :class="yz?' animate__animated animate__bounceInRight':''"
                  >
                  <img src="./image/zj1.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
    <div class="module">
      <div class="wrapper">
        <div class="module-title">
          <div class="mt_line"></div>
        </div>

        <div class="ny_kw " v-show="bz">
          <div class="container">
            <div class="row">
              <div class="col-sm-8" >
                <div class="pro1_pic "
                  :class="bz?'animate__animated animate__bounceInLeft':''"
                  >
                  <img src="./image/zj2.png" alt="">
                </div>
              </div>
              <div class="col-sm-4 " >
                <div class="pro1_txt "
                  :class="bz?'animate__animated animate__bounceInRight':''"
                  >
                  <div class="pro1_bt ">
                    摆闸
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="module">
      <div class="wrapper">
        <div class="module-title">
          <div class="mt_line"></div>
        </div>
        <div class="ny_kw " v-show="zzj">
          <div class="container">
            <div class="row">
              <div class="col-sm-4 " >
                <div class="pro1_txt">
                  <div class="pro1_bt "
                    :class="zzj?'animate__animated animate__bounceInLeft':''"
                    >
                    自助机
                  </div>
                </div>
              </div>
              <div class=" col-sm-8" >
                <div class="pro1_pic "
                  :class="zzj?'animate__animated animate__bounceInRight':''"
                  >
                  <img src="./image/zj5.png" alt="">
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="zzj_another "
        :class="zzj?'animate__animated animate__bounceInRight':''"
        >
        <img src="./image/zzj02.png" alt="">
      </div>
    </div>
    <div class="module">
      <div class="wrapper">
        <div class="module-title">
          <div class="mt_line"></div>
        </div>

        <div class="ny_kw  " v-show="scj">
          <div class="container">
            <div class="row">
              <div class=" col-sm-8" >
                <div class="pro1_pic "
                  :class="scj?'animate__animated animate__bounceInLeft':''"
                  >
                  <img src="./image/zj3.png" alt="">
                </div>
              </div>
              <div class="col-sm-4 " >
                <div class="pro1_txt">
                  <div class="pro1_bt  "
                    :class="scj?'animate__animated animate__bounceInRight':''"
                    >
                    手持机
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <footerPage />
    </div>
  </div>
</template>

<script>
  import './../../../static/js/bootstrap'
  import {WOW} from 'wowjs'
  import footerPage from '../../components/footer/footer'
  export default {
    name: "ai",
    components:{
      footerPage
    },
    data() {
      return {
        yz:false,
        bz:false,
        zzj:false,
        scj:false
      }
    },
    mounted() {
      let wow0 = new WOW({
        boxClass: 'wow0',
        animateClass: 'animate__animated animate__bounceInLeft',
        offset: 0,
        mobile: false
      })
      let wow1 = new WOW({
        boxClass: 'wow1',
        animateClass: 'animate__animated animate__bounceInRight',
        offset: 0,
        mobile: false
      })
      wow0.init();
      wow1.init()
    },
    methods: {
      handleScroll(e){
        let scrollTop = e.srcElement.scrollTop
        if(scrollTop>500 && scrollTop<1000){
          this.yz = true
        }
        if(scrollTop>1000 && scrollTop<1600){
          this.bz = true
        }
        if(scrollTop>1600 && scrollTop<2400){
          this.zzj = true
        }
        if(scrollTop>2400){
          this.scj = true
        }
      }
    },
  }
</script>

<style  lang='less' scoped>
@import url('./../../../static/css/bootstrap.css');
.main{
  width: 100%;
  height: 100%;
  overflow-y: auto;
  // .top {
  //   width: 100%;
  //   height: 40vh;
  //   // background-image: url('./../../../static/images/ai/banner-cn.jpg');
  //   background-repeat: no-repeat;
  //   background-size: 100% 100%;
  // }
  .banner{
    width: 100%;
    img{
      width: 100%;
      border: 0;
      display: block;
    }
  }
  .module {
    height: 600px;
    margin: 50px 0;
    .wrapper {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      .module-title {
        margin-bottom: 40px;
        font-size: 26px;
        color: #0080cc;
        text-align: center;
        font-weight: normal;
        span {
          display: block;
          color: #818181;
          text-transform: uppercase;
          font-size: 19px;
          margin-top: 5px;
        }
      }
      .mt_line {
        width: 100%;
        height: 83px;
        background: url('./../../../static/images/ai/btline.png') no-repeat center top;
      }
      .ny_kw {
        width: 100%;
        margin: 0 auto;
        // background: url('./../../../static/images/ai/btjt.png') no-repeat center top #fff;
        padding-top: 70px;
        padding-bottom: 70px;
      }
      .ny_kg {
        background-color: #f3f3f3;
      }
    }
  }
  .pro1_pic {
    width: 98%;
    img{
      width: 100%;
    }
  }
  .pro1_txt {
    width: 90%;
    margin: 25px auto;
    .pro1_bt {
      background: url('./../../../static/images/ai/btk.png') no-repeat left top;
      height: 80px;
      padding: 27px 0 0 55px;
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      span{
        display: block;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
    .pro1_nr {
      margin-top: 30px;
      padding-top: 30px;
      background: url('./../../../static/images/ai/btline2.jpg') no-repeat top left;
      color: #585858;
      font-size: 16px;
      line-height: 36px
    }
  }
}
.zzj_another{
  width: 100%;
  text-align: center;
  img{
    width: 60%;
  }
}
.footer{
  margin-top: 20px;
  width: 100%;
}
</style>
