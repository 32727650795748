<template>
  <div class="main">
    <div class="top animate__animated animate__bounceInLeft"></div>
    <div class="module">
      <div class="wrapper">
        <div class="module-title">
          无人车
          <span>Unmanned vehicle</span>
          <div class="mt_line"></div>
        </div>

        <div class="ny_kw">
          <div class="container">
            <div class="row">
              <div class="col-sm-8" >
                <div class="pro1_pic animate__animated animate__bounceInLeft">
                  <img src="./../../../static/images/ai/car_00.png" alt="">
                </div>
              </div>
              <div class="col-sm-4" >
                <div class="pro1_txt animate__animated animate__bounceInRight">
                  <div class="pro1_bt ">
                    无人驾驶电动巴士
                    <span>Unmanned vehicle of electric bus</span>
                  </div>
                  <div class="pro1_nr">
                    无人驾驶电动巴士“阿波龙”是全球首款 L4 级量产自动驾驶巴士; <br>
                    车身上配备了激光雷达，毫米波雷达，超声波雷达，单目及双目相机，多传感器融合，就好像人的眼睛一样，能够......
<!--                    实时辨识四周的情况。车顶的组合导航系统，结合高精地图和高精定位技术，实现的是厘米级别的定位。-->
<!--                    通过多传感器融合互补、核心计算模块冗余设计、自动驾驶状态实时监控、软硬一体的网关模块等保证功能安全和网络安全。-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ny_kw ny_kg" >
          <div class="container">
            <div class="row">
              <div class="col-sm-4" >
                <div class="pro1_txt animate__animated animate__bounceInRight">
                  <div class="pro1_bt ">
                    自动售卖车
                    <span>Unmanned vehicle of electric buy</span>
                  </div>
                  <div class="pro1_nr">
                    自动售卖车集售卖车和自动驾驶于一体，是无人驾驶商用车的先行者，硬件方面装配了前视摄像头、激光雷达、天线、RTK、计算单元、V2X通讯套装以及黑匣子和安全网关；<br>
                    软件方面采用Apollo云端管理平台以及运营商云端管理平台，并搭配有车端交互系统和车端自动驾驶系统。
                  </div>
                </div>
              </div>
              <div class="col-sm-8" >
                <div class="pro1_pic animate__animated animate__bounceInLeft">
                  <img src="./../../../static/images/ai/car_01.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="module">
      <div class="wrapper">
        <div class="module-title">
          AI机器人
          <span>artificial intelligence</span>
          <div class="mt_line"></div>
        </div>

        <div class="ny_kw wow0" data-wow-duration="1s" data-wow-delay="1s" >
          <div class="container">
            <div class="row">
              <div class="col-sm-4" >
                <div class="pro1_pic">
                  <img src="./../../../static/images/ai/ai00.png" alt="">
                </div>
              </div>
              <div class="col-sm-8" >
                <div class="pro1_txt">
                  <div class="pro1_bt ">
                    售票机器人
                    <span>Unmanned vehicle of electric bus</span>
                  </div>
                  <div class="pro1_nr">
                    将景区售票业务应用软件导入售票机器人，通过语音交流，为游客提供基础票务（票价、种类、特殊要求等）咨询、售票、付款、售票、预制票售票、网络订单查询、补录、退票等服务；<br>
                    售票和预制票售票时可语音选择业务、票种及数量、生效日期等，并进行修改、删除等操作；<br>
                    售票流程的所有操作都通过语音对话进行，通过语音交流，给游客提供更人性化的服务；<br>
                    售票后，可进行人脸采集、门票打印引导、入口引导等服务。
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="ny_kw ny_kg wow1" data-wow-duration="1s" data-wow-delay="1s" >
          <div class="container">
            <div class="row">
              <div class="col-sm-8" >
                <div class="pro1_txt">
                  <div class="pro1_bt ">
                    咨询机器人
                    <span>Unmanned vehicle of electric buy</span>
                  </div>
                  <div class="pro1_nr">
                    将景区业务与机器人语音识别、合成、交互、自然语言处理等功能相结合，通过应用系统，整合景区基础业务需求与处理流程，与机器人语音处理功能结合，代替人工给游客提供基础的景区咨询服务、引导服务、讲解服务等。<br>
                    结合人脸识别技术，记录每个咨询过的人员的基础信息，再次遇见的时候主动打招呼问候，实现基础互动。
                  </div>
                </div>
              </div>
              <div class="col-sm-4" >
                <div class="pro1_pic">
                  <img src="./../../../static/images/ai/ai01.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="module">
      <div class="wrapper">
        <div class="module-title">
          AI智慧
          <span>artificial intelligence</span>
          <div class="mt_line"></div>
        </div>
        <div class="ny_kw">
          <div class="container">
            <div class="row">
              <div class="col-sm-8 wow0" data-wow-duration="1s" data-wow-delay="1s" >
                <div class="pro2np con">
                  <div class="pro2n_pic"><img src="./../../../static/images/ai/ai_00.png" alt="">
                  </div>
                  <div class="pro2n_txt txt">
                    <h3>AI健身大师</h3>
                    <p>
                      健身大师依托人体识别技术，游客在指定区域，跟随大屏上视频教学内容运动时，借助AR增强现实功能，通过人体识别，利用摄像头装置捕捉人体动作，AI深度算法分析人体动作，判断动作是否标准，进而达到人体动作控制和判断的效果。
                    </p>
                    <p>健身大师具备图像采集、视觉计算、人体识别与比对技术和AR能力，将AR应用与运动相结合，增加运动趣味性和互动性，既达到了运动的效果，又实现了娱乐化体验。</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 wow1" data-wow-duration="0.5s" data-wow-delay="0.5s" >
                <div class="pro2np con">
                  <div class="pro2n_pic"><img src="./../../../static/images/ai/ai_01.png" alt="">
                  </div>
                  <div class="pro2n_txt txt"><h3>人脸识别便民柜</h3>
                    <p>人脸识别便民柜将人脸识别技术应用到便民柜上，游客通过刷脸实现物品存储和领取，为其提供便捷化、科技感的应用；</p>
                    <p>存物品时，采集人脸并支付即可存放物品；</p>
                    <p>取物品时，直接通过便民柜上的人脸识别应用进行验证即可开箱取物。</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 wow0" data-wow-duration="0.5s" data-wow-delay="0.5s">
                <div class="pro2np con">
                  <div class="pro2n_pic"><img src="./../../../static/images/ai/ai_02.png" alt="">
                  </div>
                  <div class="pro2n_txt txt"><h3>智能垃圾桶</h3>
                    <p>蓝色箱体主要收集织物、纸张、金属、玻璃、塑料、塑料瓶等六种可回收物；红色箱体用来收集有害垃圾，箱体上巧妙设计多种投口，将有害垃圾分别回收；</p>
                    <p>最大的亮点是可通过通过人脸识别系统与游客票务绑定，根据游客垃圾分类情况给予一定的奖励；</p>
                    <p>主要功能：人脸识别、触屏操作、通风稳定、超级防盗、温度检测、满载警示预警、监控摄像、自动灭火、户外防水、安全定位等。</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 wow1" data-wow-duration="1s" data-wow-delay="1s" >
                <div class="pro2np con">
                  <div class="pro2n_pic"><img src="./../../../static/images/ai/ai_03.png" alt="">
                  </div>
                  <div class="pro2n_txt txt"><h3>智能卫生间</h3>
                    <p>智能卫生间可实现坑位监测，在基础卫生间使用功能基础上，还可为市民及游客提供预计排队等待时间、实时使用情况、附近卫生间查找、精确的卫生间位置导航等服务。
                      </p><p>管理人员可在综合管控平台上直观查看厕所分布情况和坑位应用情况；游客可通过百度小程序查找附近卫生间，了解坑位使用情况。</p>
                    <p>智能厕所是践行“厕所革命”和“旅游厕所革命”的的重要举措，是构建全要素旅游服务链的重要环节。</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <footerPage />
    </div>
  </div>
</template>

<script>
  import footerPage from '../../components/footer/footer'
  import './../../../static/js/bootstrap'
  import {WOW} from 'wowjs'
  export default {
    components:{
      footerPage
    },
    name: "ai",
    mounted() {
      let wow0 = new WOW({
        boxClass: 'wow0',
        animateClass: 'animate__animated animate__bounceInLeft',
        offset: 0,
        mobile: false
      })
      let wow1 = new WOW({
        boxClass: 'wow1',
        animateClass: 'animate__animated animate__bounceInRight',
        offset: 0,
        mobile: false
      })
      wow0.init();
      wow1.init();
      $(".con").hover(function(){
        $(this).find(".txt").stop().animate({height:"380px"},200);
        $(this).find(".txt h3").stop().animate({paddingTop:"60px", paddingBottom: "40px"},200);
      },function(){
        $(this).find(".txt").stop().animate({height:"35px"},200);
        $(this).find(".txt h3").stop().animate({paddingTop:"0", paddingBottom: "0"},200);
      })
    }
  }
</script>

<style  lang='less' scoped>
@import url('./../../../static/css/bootstrap.css');
.main{
  width: 100%;
  height: 100%;
  .top {
    width: 100%;
    height: 50vh;
    background-image: url('./../../../static/images/ai/banner-cn.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .module {
    margin: 50px 0;
    .wrapper {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      .module-title {
        margin-bottom: 40px;
        font-size: 26px;
        color: #0080cc;
        text-align: center;
        font-weight: normal;
        span {
          display: block;
          color: #818181;
          text-transform: uppercase;
          font-size: 19px;
          margin-top: 5px;
        }
      }
      .mt_line {
        width: 100%;
        height: 83px;
        background: url('./../../../static/images/ai/btline.png') no-repeat center top;
      }
      .ny_kw {
        width: 100%;
        margin: 0 auto;
        background: url('./../../../static/images/ai/btjt.png') no-repeat center top #fff;
        padding-top: 70px;
      }
      .ny_kg {
        background-color: #f3f3f3;
      }
    }
  }
  .pro1_pic {
    width: 98%;
    img{
      width: 100%;
    }
  }
  .pro1_txt {
    width: 90%;
    margin: 25px auto;
    .pro1_bt {
      background: url('./../../../static/images/ai/btk.png') no-repeat left top;
      height: 80px;
      padding: 18px 0 0 15px;
      font-size: 18px;
      color: #333333;
      span{
        display: block;
        font-size: 12px;
        text-transform: uppercase;
      }
    }
    .pro1_nr {
      margin-top: 30px;
      padding-top: 30px;
      background: url('./../../../static/images/ai/btline2.jpg') no-repeat top left;
      color: #585858;
      font-size: 16px;
      line-height: 36px
    }
  }
  .pro2np {
    background: #fff;
    box-shadow: 0 0 5px 5px rgba(0,0,0,.08);
    width: 98%;
    height: 400px;
    margin: 0 auto 35px;
    padding: 8px;
    position: relative;
    img {
      width: 100%;
      height: 370px;
    }
    .pro2n_txt {
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      background: rgba(0,0,0,.6);
      margin: 15px 0;
      font-size: 16px;
      line-height: 24px;
      color: #fff;
      height: 35px;
      padding: 0 18px;
      overflow: hidden;
      h3{
        margin: 0;
        line-height: 35px;
        text-align: center;
      }
      p{
        text-indent: 2em;
      }
    }
  }
}
</style>
