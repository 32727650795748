<template>
  <div>
    <div class="banner animate__animated animate__bounceInLeft">
      <img src="./images/banner.jpg" alt="">
    </div>
    <div class="module-title animate__animated animate__fadeInUp content">
      <h2>{{title}}</h2>
      <div class="nfrom">
        <span>发表时间：{{dateandtime}}</span>
        <span>来自：{{nfrom}}</span>
        <span>作者：{{zznews}}</span>
      </div>
      <div v-html="info">
        {{info}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title:'',
      zznews:'',
      nfrom:'',
      dateandtime:'',
      info:''
    }
  },
  created() {
    this.getInfo()
  },
  methods: {
    getInfo(){
      $.ajax({
        url:'/article/detailJson.do',
        data:{
          "id": this.$route.query.id
        },
        dataType:'jsonp',
        type:'get',
        cache:false,
        timeout:5000,
        success:(res)=>{
          if(res.code == 'success'){
            this.title=res.title
            this.zznews=res.zznews
            this.nfrom=res.nfrom
            this.dateandtime=res.dateandtime
            this.info = decodeURIComponent(res.content)
          }
        },
        error:(err)=>{
          console.log(err)
        }
      })
    }
  },
}
</script>

<style lang='less' scoped>
@import url('../../assets/css/content.less');
.module-title{
  h2{
    display: block;
    width: 100%;
    text-align: center;
  }
  .nfrom{
    text-align: center;
  }
}
</style>
