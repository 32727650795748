import { render, staticRenderFns } from "./O2OpmsSystem.vue?vue&type=template&id=5564ddda&scoped=true&"
import script from "./O2OpmsSystem.vue?vue&type=script&lang=js&"
export * from "./O2OpmsSystem.vue?vue&type=script&lang=js&"
import style0 from "./O2OpmsSystem.vue?vue&type=style&index=0&id=5564ddda&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5564ddda",
  null
  
)

export default component.exports