<template>
  <div class="about_box " @scroll="handleScroll">
    <div class="banner animate__animated animate__bounceInLeft">
      <img src="./images/banner.jpg" alt="">
    </div>
    <div class="content about_nav">
      <div @click="changNav(1)" :class="ishow == 1?'active':''">
        <span>企业介绍</span>
        <i></i>
      </div>
      <div @click="changNav(2)" :class="ishow == 2?'active':''">
        <span>董事长致辞</span>
        <i></i>
      </div>
      <div @click="changNav(3)" :class="ishow == 3?'active':''">
        <span>部分资质荣誉</span>
        <i></i>
      </div>
    </div>
    
    <div>
      <div v-show="ishow == 1" >
        <introdus />
      </div>
      <div v-show="ishow == 2" >
        <dsz />
      </div>
      <div v-show="ishow == 3" >
        <honors />
      </div>
    </div>
    
    <div class="footer">
      <footerPage />
    </div>
  </div>
</template>

<script>
import introdus from './options/introdus'
import dsz from './options/dsz'
import honors from './options/honors'
import footerPage from '../../components/footer/footer'
export default {
  components:{
    introdus,
    dsz,
    honors,
    footerPage
  },
  data() {
    return {
      isShowActive:false,
      list:[
        {
          name:'高新技术企业',
          url:require('./images/gxjsqy.jpg')
        },
        {
          name:'软件企业证书',
          url:require('./images/rjqyzs.png')
        },
        {
          name:'软件产品登记证书',
          url:require('./images/rjcpdjzs.png')
        },
        {
          name:'信息系统集成证书',
          url:require('./images/xxxtjc.png')
        },
        
        {
          name:'CMMI证书',
          url:require('./images/05CMMI.jpg')
        },
        {
          name:'质量管理体系认证证书',
          url:require('./images/zlgltxrz.png')
        },
        {
          name:'参与国家科技部十一五重大科技支撑计划数字旅游课题',
          url:require('./images/07.png')
        },
        {
          name:'主持制定中华人民共和国城镇建设行业标准',
          url:require('./images/08.png')
        },
        {
          name:'O2O综合票务系统-软件著作权',
          url:require('./images/09.jpg')
        },
        {
          name:'大数据展示-软件著作权',
          url:require('./images/10.jpg')
        },
      ],
      ishow:1
    }
  },
  methods: {
    handleScroll(e){
      let scrollTop = e.srcElement.scrollTop
      if(scrollTop>730){
        this.isShowActive = true
      }
    },
    changNav(e){
      this.ishow = e
    }
  },
}
</script>

<style lang='less' scoped>
@import url('../../assets/css/content.less');
.about_box{
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.banner{
  width: 100%;
  img{
    width: 100%;
    border: 0;
    display: block;
  }
}
.footer{
  width: 100%;
}
.mt_line {
  width: 100%;
  height: 83px;
  margin-bottom: 20px;
  background: url('./../../../static/images/ai/btline.png') no-repeat center top;
}
.about_nav{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-bottom: 2px solid #cccccc;
  div:hover{
    font-size: 22px;
    font-weight: 600;
    color: red;
    i{
      border-color: red !important;
    }
  }
  div{
    width: 33.33%;
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    font-size: 20px;
    position: relative;
    span{
      line-height: 50px;
    }
    i{
      position: absolute;
      display: block;
      width: 20px;
      height: 20px;
      border: 2px solid #cccccc;
      border-radius: 50%;
      overflow: hidden;
      background-color: #ffffff;
      text-align: center;
      left: 48%;
      bottom: -10px;
    }
  }
}
.active{
  font-size: 22px !important;
  font-weight: 600 !important;
  color: red;
  i{
    border-color: red !important;
  }
}
</style>
