<template>
  <div class="module-title content animate__animated animate__fadeInUp"  >
    <div class="title">部分资质荣誉</div>
    <div class="cop_info ">
      <div v-for="(item, index) in list" :key="index">
        <img :src="item.url" alt="" :title="item.name">
        <p>{{item.name}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list:[
        {
          name:'高新技术企业',
          url:require('../images/gxjsqy.jpg')
        },
        {
          name:'软件企业证书',
          url:require('../images/rjqyzs.png')
        },
        {
          name:'软件产品登记证书',
          url:require('../images/rjcpdjzs.png')
        },
        {
          name:'信息系统集成证书',
          url:require('../images/xxxtjc.png')
        },
        
        {
          name:'CMMI证书',
          url:require('../images/05CMMI.jpg')
        },
        {
          name:'质量管理体系认证证书',
          url:require('../images/zlgltxrz.png')
        },
        {
          name:'参与国家科技部十一五重大科技支撑计划数字旅游课题',
          url:require('../images/07.png')
        },
        {
          name:'主持制定中华人民共和国城镇建设行业标准',
          url:require('../images/08.png')
        },
        {
          name:'O2O综合票务系统-软件著作权',
          url:require('../images/09.jpg')
        },
        {
          name:'大数据展示-软件著作权',
          url:require('../images/10.jpg')
        },
      ]
    }
  },
}
</script>

<style lang='less' scoped>
@import url('../../../assets/css/content.less');
.module-title {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 26px;
  text-align: center;
  min-height: 500px;
}
.cop_info{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 60px;
  
  div{
    width: 25%;
    height: 450px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    img{
      display: inline-block;
      width: 80%;
    }
    p{
      width: 90%;
      text-align: center;
      display: block;
      font-size: 16px;
    }
  }
}
.title{
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
}
</style>