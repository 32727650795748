<template>
	<div id="app">
		<header-nav/>
		<div class="view" :class="$route.path=='/home'?'':'view_flag'">
			<router-view/>
		</div>
		<div class="live800"><a
			href="https://chat.ectrip.com/chat/chatClient/chatbox.jsp?companyID=89332655&configID=128" target="_blank">
			<span class="liveImg">...</span>
			<span class="liveText">在线咨询</span></a>
		</div>
	</div>
</template>

<script>
window.onload = function () {
	document.addEventListener("touchstart", function (event) {
		if (event.touches.length > 1) {
			event.preventDefault();
		}
	});
	document.addEventListener("gesturestart", function (event) {
		event.preventDefault();
	});
};
import headerNav from "./components/header/header";
import LwFirewords from "lw_firewords"; //引入烟花特效
export default {
	components: {
		headerNav
	},
	data() {
		return {};
	},
	mounted() {
		//使用烟花特效
		const lw_f = new LwFirewords();
		lw_f.init();
	}
};
</script>

<style lang="less">
// ::-webkit-scrollbar {
//   display: none;
// }
#app {
	height: 100%;
	width: 100%;
	// min-width: 1400px;
	cursor: pointer;
}

html,
body {
	height: 100%;
	width: 100%;
	cursor: pointer;
}

.view {
	height: 100%;
	width: 100%;
}

.view_flag {
	padding-top: 75px;
	box-sizing: border-box;
}

.live800 {
	position: fixed;
	right: 10px;
	top: 50%;
	z-index: 100;
	width: 80px;
	display: block;
	text-align: center;
	color: #FFF;

	a:link,
	a:visited,
	a:hover {
		color: #FFF;
		text-decoration: none;
	}

	.liveImg {
		background-image: url("../public/images/iconMsg.png");
		background-size: cover;
		width: 60px;
		height: 66px;
		display: block;
		line-height: 40px;
		font-size: 48px;
		margin: auto;
	}

	.liveText {
		background-color: #ffa200;
		display: block;
		padding: 6px;
		margin-top: 5px;
		font-size: 14px;
		border-radius: 15px;
		height: 30px;
	}

	a:hover .liveText {
		background-color: #ca840c;
	}

}
</style>
