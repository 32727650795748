<template>
  <div class="lbx-out" :style="size">
    <div class="lbx-in" @click="to" @mouseenter="mouseenter" @mouseleave="mouseleave">
      <img class="lbx-img" :src="imageSrc" alt="">
      <div class="lbx-text" 
        :class="active?'lbx-active':'lbx-close-active'">
        <div :class="active?'lbx-text-active':'lbx-close-text-active'">
          <span>{{title}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    title:{
      type:String
    },
    titleBottom:{
      type:String
    },
    imageSrc:{
      type:String
    },
    routerStr:{
      type:String
    },
    size:{
      type:Object
    }
  },
  data() {
    return {
      active:false
    }
  },
  methods: {
    mouseenter(){
      this.active = true
    },
    mouseleave(){
      this.active = false
    },
    to(){
      if(this.routerStr){
        this.$router.push(this.routerStr)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.lbx-out{
  width: 200px;
  height: 230px;
  overflow: hidden;
  transform: rotate(-30deg) skewY(30deg);
  position: relative;
  margin: 0 3%;
}
.lbx-in{
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: #ffffff;
  overflow: hidden;
  transform: skewY(-30deg) rotate(60deg) translateZ(0);
  cursor: pointer;
  position: relative;
}
.lbx-img{
  display: block;
  width: 130%;
  height: 100%;
  text-align: center;
  color: #fff;
  overflow: hidden;
  position: absolute;
  transform: rotate(-30deg);
  margin: 0 auto;
  left: -100%;
  right: -100%;
}
.lbx-text{
  width: auto;
  height: 100%;
  text-align: center;
  margin: 0 auto;
  left: -100%;
  right: -100%;
  color: #fff;
  overflow: hidden;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  transform: rotate(-30deg) translateY(70%);
  cursor: pointer;
}
.lbx-text>div{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.lbx-text>div>span{
  font-size: 16px;
  line-height: 40px;
  // font-weight: 700;
  color: #ffffff;
  cursor: pointer;
}
.lbx-active{
  animation: lbx-text linear 0.5s;
  background-color: rgba(53, 192, 225, 0.8);
  transform: rotate(-30deg) translateY(0);
}
.lbx-close-active{
  animation: lbx-text-close linear 0.5s;
  background-color: rgba(0, 0, 0, 0.5);
  transform: rotate(-30deg) translateY(70%);
}
@keyframes lbx-text{
  0%{
    transform: rotate(-30deg) translateY(70%);
  }
  10%{
    transform: rotate(-30deg) translateY(63%);
  }
  20%{
    transform: rotate(-30deg) translateY(56%);
  }
  30%{
    transform: rotate(-30deg) translateY(49%);
  }
  40%{
    transform: rotate(-30deg) translateY(42%);
  }
  50%{
    transform: rotate(-30deg) translateY(35%);
  }
  60%{
    transform: rotate(-30deg) translateY(28%);
  }
  70%{
    transform: rotate(-30deg) translateY(21%);
  }
  80%{
    transform: rotate(-30deg) translateY(14%);
  }
  90%{
    transform: rotate(-30deg) translateY(7%);
  }
  100%{
    transform: rotate(-30deg) translateY(0);
  }
}
@keyframes lbx-text-close{
  0%{
    transform: rotate(-30deg) translateY(0);
  }
  10%{
    transform: rotate(-30deg) translateY(7%);
  }
  20%{
    transform: rotate(-30deg) translateY(14%);
  }
  30%{
    transform: rotate(-30deg) translateY(21%);
  }
  40%{
    transform: rotate(-30deg) translateY(28%);
  }
  50%{
    transform: rotate(-30deg) translateY(35%);
  }
  60%{
    transform: rotate(-30deg) translateY(42%);
  }
  70%{
    transform: rotate(-30deg) translateY(49%);
  }
  80%{
    transform: rotate(-30deg) translateY(56%);
  }
  90%{
    transform: rotate(-30deg) translateY(63%);
  }
  100%{
    transform: rotate(-30deg) translateY(70%);
  }
}
.lbx-text-active{
  animation: lbx-text-box linear 0.5s;
  transform: translateY(50%);
}
.lbx-close-text-active{
  font-size: 12px;
  animation: lbx-close-text-box linear 0.5s;
  transform: translateY(3%);
}
@keyframes lbx-text-box{
  0%{
    transform: translateY(3%);
  }
  10%{
    transform: translateY(7.7%);
  }
  20%{
    transform: translateY(12.4%);
  }
  30%{
    transform: translateY(17.1%);
  }
  40%{
    transform: translateY(21.8%);
  }
  50%{
    transform: translateY(26.5%);
  }
  60%{
    transform: translateY(31.2%);
  }
  70%{
    transform: translateY(35.9%);
  }
  80%{
    transform: translateY(40.6%);
  }
  90%{
    transform: translateY(45.3%);
  }
  100%{
    transform: translateY(50%);
  }
}
@keyframes lbx-close-text-box{
  0%{
    transform: translateY(50%);
  }
  10%{
    transform: translateY(45.3%);
  }
  20%{
    transform: translateY(40.6%);
  }
  30%{
    transform: translateY(35.9%);
  }
  40%{
    transform: translateY(31.2%);
  }
  50%{
    transform: translateY(26.5%);
  }
  60%{
    transform: translateY(21.8%);
  }
  70%{
    transform: translateY(17.1%);
  }
  80%{
    transform: translateY(12.4%);
  }
  90%{
    transform: translateY(7.7%);
  }
  100%{
    transform: translateY(3%);
  }
}
</style>
