<template>
  <div class="content_main" @scroll="handleScroll">
    <div class="banner animate__animated animate__bounceInLeft">
      <img src="../../assets/images/n-banner02.jpg" alt="">
    </div>
    <div class="bg animate__animated animate__fadeInUp">
      <div class="module-title">
        畅游通分销平台
        <span>Changyoutong distribution platform</span>
      </div>
      <div class="module-title">
        <div class="mt_line"></div>
        产品介绍
        <span>Product introduction</span>
      </div>
      <div class="product_introduct_text content">
        <div>
          为旅游行业拓宽分销渠道、实现财务管理和订单管理、精准营销而专门研发的B2B分销系统，是旅游供应商与分销商交易环节的中间桥梁，实现交易全过程电子化和自动化，交易过程与电子商务系统和PMS系统自动对接。
        </div>
      </div>
      <!-- <div class="product_introduct">
        <h4>子系统</h4>
      </div> -->
      <div class="module-title">
        <div class="mt_line"></div>
        功能
        <span>Subsystem</span>
        
      </div>
      <div class="product_z_system content">
        <div class="tag">
          <img src="./image/djfx.png" alt="">
          <span>多级分销</span>
        </div>
        <div class="tag">
          <img src="./image/djdl.png" alt="">
          <span>多级代理</span>
        </div>
        <div class="tag">
          <img src="./image/fxszh.png" alt="">
          <span>分销商组合产品</span>
        </div>
        <div class="tag">
          <img src="./image/pos.png" alt="">
          <span>POS验证，微信，PMS软件核销</span>
        </div>
        <div class="tag">
          <img src="./image/ewm.png" alt="">
          <span>支持二维码，身份证核销</span>
        </div>
        <div class="tag">
          <img src="./image/smfx.png" alt="">
          <span>扫码分销</span>
        </div>
      </div>
      
    </div>
    <div class="module-title">
      <div class="mt_line"></div>
      效果展示
      <span>Effect display</span>
    </div>
    <div class="content zhyx_img">
      <img src="./image/cyt.jpg" alt="">
    </div>
    <div class="footer">
      <footerPage />
    </div>
  </div>
</template>

<script>
import footerPage from '../../components/footer/footer'
export default {
  components:{
    footerPage
  },
  data() {
    return {
      tagList:[
        {
          id:0,
          name:'多级分销'
        },
        {
          id:1,
          name:'多级代理'
        },
        {
          id:2,
          name:'分销商组合产品'
        },
        {
          id:3,
          name:'POS验证，微信，PMS软件核销'
        },
        {
          id:4,
          name:'支持二维码，身份证核销'
        },
        {
          id:5,
          name:'扫码分销'
        },
      ]
    }
  },
  methods: {
    handleScroll(e){
      console.log(e)
    }
  },
}
</script>

<style scoped lang='less'>
@import '../../assets/css/content.less';
.content_main{
  height: 100%;
  width: 100%;
  
  // background-image: url('../../assets/images/image1.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.bg{
  padding-bottom: 50px;
}
.banner{
  width: 100%;
  img{
    width: 100%;
    border: 0;
    display: block;
  }
}
.title{
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  h4{
    font-size: 30px;
  }
}
.module-title {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 26px;
  color: #0080cc;
  text-align: center;
  font-weight: normal;
  span {
    display: block;
    color: #818181;
    text-transform: uppercase;
    font-size: 19px;
    margin-top: 5px;
  }
}
.mt_line {
  width: 100%;
  height: 83px;
  margin-bottom: 20px;
  background: url('./../../../static/images/ai/btline.png') no-repeat center top;
}
.product_introduct{
  text-align: center;
  height: 80px;
  line-height: 80px;
  font-size: 2rem;
  background: #f5f5f5;
  margin: 0 10%;
  h4{
    font-size: 30px;
    line-height: 80px;
  }
}
.product_introduct_text{
  margin-top: 2rem;
  div{
    font-size: 18px;
    line-height: 40px;
    text-indent:3rem;
    margin-bottom: 20px;
  }
}
.product_z_system{
  margin-top: 2rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .tag{
    width: 29.333%;
    margin: 1% 2%;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    img{
      height: 90%;
    }
    span{
      width: 80%;
      display: inline-block;
      text-align: left;
    }
  }
}
.image_box{
  margin: 40px 0;
  img{
    width: 100%;
    margin: 20px 0;
  }
}
.zhyx_img{
  img{
    width: 100%;
  }
}
.footer{
  width: 100%;
  margin-top: 20px;
}
</style>
