<template>
  <div class="swiper_box" >
    <swiper :options="swiperOption" ref="firstSwiper">
      <swiper-slide style="margin:0; height:100%;width: 100%;">
        <div @click="goPage" class="img" :style="{backgroundImage:img01}"></div>
      </swiper-slide>
      <!-- <swiper-slide style="margin:0;height:100%;width: 100%;">
        <div class="img" :style="{backgroundImage:img02}"></div>
      </swiper-slide> -->
      <swiper-slide style="margin:0;height:100%;width: 100%;">
        <div class="img" :style="{backgroundImage:img03}"></div>
        <!-- <img class="img" src="../images/h-banner05.jpg" alt=""> -->
      </swiper-slide>
      <!-- <swiper-slide style="margin:0;height:100%;width: 100%;">
        <div class="img" :style="{backgroundImage:img04}"></div>
      </swiper-slide> -->
      <swiper-slide style="margin:0;height:100%;width: 100%;">
        <div class="img" :style="{backgroundImage:img05}"></div>
      </swiper-slide>
      <swiper-slide style="margin:0;height:100%;width: 100%;">
        <div class="img" :style="{backgroundImage:img06}"></div>
      </swiper-slide>
      <swiper-slide style="margin:0;height:100%;width: 100%;">
        <div class="img" :style="{backgroundImage:img07}"></div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
export default {
  data() {
    return {
      swiperOption: {
        slidesPerView: 1,
        autoplay: {
          delay: 3000,
          disableOnInteraction: true // 手动切换之后继续自动轮播
        },
        loop: true,
      },
      screenWidth: document.body.clientWidth,
      screenHeight: document.body.clientHeight,
      img01:'url("'+require('../images/h-banner01.png')+'")',
      // img01:'url("'+require('../images/h-banner01.jpg')+'")',
      // img01:'url("'+require('../../../assets/images/banner.jpg')+'")',
      // img02:'url("'+require('../images/h-banner03.jpg')+'")',
      img03:'url("'+require('../images/h-banner05_1.png')+'")',
      // img04:'url("'+require('../images/h-banner07.jpg')+'")',
      img05:'url("'+require('../images/h-banner10.png')+'")',
      img06:'url("'+require('../images/h-banner12.png')+'")',
      img07:'url("'+require('../images/h-banner13.jpg')+'")',
    }
  },
  computed: {
    swiper () {
      return this.$refs.firstSwiper.swiper
    }
  },
  mounted () {

  },
  watch: {
    screenWidth (val) {
      this.screenWidth = val
    }
  },
  methods: {
    goPage() {
      window.open('http://tour.12301cn.cn/#/login')
    }
  }
}
</script>

<style lang='less' scoped>

.swiper_box{
  width: 100%;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
  .zx_box{
    flex: 1;
  }

}
.swiper_box>.swiper-container{
  height: 100% !important;
}
.swiper_box>.swiper-container>.swiper-wrapper{
  height: 100% !important;
}
.img{
  // width: 100%;
  height: 100%;
  display: block;
  border: 0;
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center center;
}
</style>
