<template>
  <div class="content_main" @scroll="handleScroll">
    <div class="banner animate__animated animate__bounceInLeft">
      <img src="./images/bannerVillage.jpg" alt="">
    </div>
    <div class="animate__animated animate__fadeInUp">
      <div class="module-title">
		  美丽乡村解决方案
        <div class="mt_line"></div>
      </div>
      <div class="programme content">
		  <div class="programme_box">
			  <div>
				  <img src="./images/iconsNote_01.png" alt="">
			  </div>
			  <div>5G直播
			  </div>
		  </div>
		  <div class="programme_box">
			  <div>
				  <img src="./images/iconsNote_02.png" alt="">
			  </div>
			  <div>严选商城
			  </div>
		  </div>
		  <div class="programme_box">
			  <div>
				  <img src="./images/iconsNote_03.png" alt="">
			  </div>
			  <div>互动体验中心
			  </div>
		  </div>
		  <div class="programme_box">
			  <div>
				  <img src="./images/iconsNote_04.png" alt="">
			  </div>
			  <div>美丽乡村运营
			  </div>
		  </div>
		  <div class="programme_box">
			  <div>
				  <img src="./images/iconsNote_05.png" alt="">
			  </div>
			  <div>数字农场
			  </div>
		  </div>
		  <div class="programme_box">
			  <div>
				  <img src="./images/iconsNote_06.png" alt="">
			  </div>
			  <div>区块链应用
			  </div>
		  </div>
		  <div class="programme_box">
			  <div>
				  <img src="./images/iconsNote_07.png" alt="">
			  </div>
			  <div>数治化户籍管理</div>
        </div>
      </div>
    </div>
    

    <div>
      <footerPage />
    </div>
  </div>
</template>

<script>
import hexagon from '../../components/hexagon/hexagon'
import footerPage from '../../components/footer/footer'
export default {
  components:{
    hexagon,
    footerPage
  },
  data() {
    return {
      hexagonList:[
        {
          id:0,
          title:'广东丹霞山',
          srcUrl:require('./images/jq-dxs.png')
        },
        {
          id:1,
          title:'神农架',
          srcUrl:require('./images/jq-snj.jpg')
        },
        {
          id:2,
          title:'武夷山',
          srcUrl:require('./images/jq-wys.jpg')
        },
        {
          id:3,
          title:'张掖丹霞',
          srcUrl:require('./images/jq-zydx.jpg')
        }
      ]
    }
  },
  methods: {
    handleScroll(e){
      let scrollTop = e.srcElement.scrollTop
      
    }
  },
}
</script>

<style lang='less' scoped>
@import url('../../assets/css/content.less');
.content_main{
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.banner{
  width: 100%;
  img{
    width: 100%;
    border: 0;
    display: block;
  }
}
.module-title {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 26px;
  color: #0080cc;
  text-align: center;
  font-weight: normal;
}
.mt_line {
  width: 100%;
  height: 83px;
  margin-bottom: 20px;
  background: url('./../../../static/images/ai/btline.png') no-repeat center top;
}
.programme{
  margin-top: 2rem;
  width: 100%;
  display: flex;
  border-radius: 10px;
  flex-wrap: wrap;
  align-items: center;
  .programme_box{
    width: 20%;
    margin: 5% 2.5%;
    height: 60px;
    line-height: 60px;
    text-align: center;
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    font-size: 18px;
    box-sizing: border-box;
    cursor: pointer;
    position: relative;
    div:first-child{
      position: absolute;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      left: -50px;
      top: -50px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      img{
        width: 60%;
      }
    }
  }
}
.hexagon_box{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 20px;
}
</style>
