<template>
  <div class="content main_box" :class="secondActive?'animate__animated animate__fadeInUp':''">
    <div class="title">
      <h2>主营业务</h2>
      <span>Main business</span>
    </div>
    <div class="main">
      <div class="a" @mouseenter="mouseenter(1)" @mouseleave="mouseleave(1)">
        <h4>智慧文旅服务</h4>
        <div class="big_data">
          <transition name="fade">
            <img v-if="o2oActive" src="../images/fw.png" alt="">
            <img v-else src="../images/fw-l.png" alt="">
          </transition>
        </div>
      </div>
      <div class="a" @mouseenter="mouseenter(2)" @mouseleave="mouseleave(2)">
        <h4>智慧文旅管理</h4>
        <div class="big_data">
          <transition name="fade">
            <img v-if="bigDataActive" src="../images/gl.png" alt="">
            <img v-else src="../images/gl-l.png" alt="">
          </transition>
        </div>
      </div>
      <div class="a" @mouseenter="mouseenter(3)" @mouseleave="mouseleave(3)">
        <h4>智慧文旅营销</h4>
        <div class="big_data">
          <transition name="fade">
            <img v-if="zhyxActive" src="../images/yx.png" alt="">
            <img v-else src="../images/yx-l.png" alt="">
          </transition>
        </div>
      </div>
      <div class="a" @mouseenter="mouseenter(4)" @mouseleave="mouseleave(4)">
        <h4>智慧文旅体验</h4>
        <div class="big_data">
          <transition name="fade">
            <img v-if="tyActive" src="../images/ty.png" alt="">
            <img v-else src="../images/ty-l.png" alt="">
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    secondActive:{
      type:Boolean
    }
  },
  data() {
    return {
      o2oActive:false,
      bigDataActive:false,
      zhyxActive:false,
      tyActive:false
    }
  },
  methods: {
    mouseenter(e){
      switch (e) {
        case 1:
          this.o2oActive = true
          break;
        case 2:
          this.bigDataActive = true
          break;
        case 3:
          this.zhyxActive = true
          break;
        case 4:
          this.tyActive = true
          break;
        default:
          break;
      }
      
    },
    mouseleave(e){
      switch (e) {
        case 1:
          this.o2oActive = false
          break;
        case 2:
          this.bigDataActive = false
          break;
        case 3:
          this.zhyxActive = false
          break;
        case 4:
          this.tyActive = false
          break;
        default:
          break;
      }
    }
  },
}
</script>

<style>
  @import '../../../assets/css/content.less';
</style>

<style scoped lang='less'>
.title{
  text-align: center;
  width: 100%;
  color: #ffffff;
}
.title>h2{
  font-size: 3rem;
}
.title>span{
  font-size: 2rem;
}
.main{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2%;
  padding-bottom: 5%;
  box-sizing: border-box;
}
.a{
  width: 25%;
  height: 100%;
  border-left: 6px solid #cccccc;
  padding-top: 2rem;
  box-sizing: border-box;
  background-color: rgba(255,255,255, 0.5);
  h4{
    display: block;
    width: 100%;
    text-align: center;
    font-size: 3rem;
    font-weight: 600;
  }
  .big_data{
    width: 100%;
    margin-top: 30%;
    height: 300px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    img{
      width: 100%;
    }
  }
}
.a:first-child{
  border-left:0;
}
.a:hover{
  background-color: rgba(53,192,255, 0.5);
  color: #ffffff;
}
.fade-enter-active {
  transition: show-product .5s linear;
}
.fade-leave-active {
  transition: close-product .5s linear;
}
// .fade-enter, .fade-leave-to {
//   opacity: 0;
// }
@keyframes show-product{
  0%{
    opacity: 0;
  }
  10%{
    opacity: 0.1;
  }
  20%{
    opacity: 0.2;
  }
  30%{
    opacity: 0.3;
  }
  40%{
    opacity: 0.4;
  }
  50%{
    opacity: 0.5;
  }
  60%{
    opacity: 0.6;
  }
  70%{
    opacity: 0.7;
  }
  80%{
   opacity: 0.8;
  }
  90%{
    opacity: 0.9;
  }
  100%{
    opacity: 1;
  }
}
@keyframes close-product{
  0%{
    opacity: 1;
  }
  10%{
    opacity: 0.9;
  }
  20%{
    opacity: 0.8;
  }
  30%{
    opacity: 0.7;
  }
  40%{
    opacity: 0.6;
  }
  50%{
    opacity: 0.5;
  }
  60%{
    opacity: 0.4;
  }
  70%{
    opacity: 0.3;
  }
  80%{
   opacity: 0.2;
  }
  90%{
    opacity: 0.1;
  }
  100%{
    opacity: 0;
  }
}
</style>
